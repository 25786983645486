import React, { useCallback, useMemo, useState } from "react";

import { useOutletContext } from "react-router-dom";

import { useSelector } from "react-redux";
import { getOrgId } from "modules/commonSelectors";
import { useGetOrgMembers } from "Settings/modules/settingsQueries";
import { useGetProjectMembers } from "Projects/modules/ProjectsQueries";
import {
  getProjectAccessLevelOptions,
  PROJECT_ACCESS_LEVEL_TYPE_VIEW,
} from "Constants/StringConstants";
import {
  useAddProjectMember,
  useDeleteProjectMember,
  useUpdateProjectMember,
} from "Projects/modules/ProjectsMutations";

import Box from "UIComponents/Box";
import Button from "UIComponents/Button";
import ProjectTeamModal from "./ProjectTeamModal";
import UsersFeedList from "AppComponents/UsersFeedList";
import classes from "./ProjectTeam.scss";

const ProjectTeam = () => {
  const { projectId, permissions, projectAccessType } = useOutletContext();
  const [showModal, setShowModal] = useState(null);

  const orgId = useSelector(getOrgId);
  const { data: orgMembers, isLoading: fetchingOrgMembers } = useGetOrgMembers({ orgId });
  const { data: projectMembers, isLoading: fetchingProjectMembers } = useGetProjectMembers({
    projectId,
  });

  const { mutate: addProjectMember } = useAddProjectMember({ projectId });
  const { mutate: deleteProjectMember } = useDeleteProjectMember({ projectId });
  const { mutate: updateProjectMember } = useUpdateProjectMember({ projectId });

  const isLoading = fetchingOrgMembers || fetchingProjectMembers;

  const allProjectAccessLevelOptions = useMemo(
    () => getProjectAccessLevelOptions({ projectAccessType: "ALL" }),
    []
  );

  const onClickAddMember = async () => {
    setShowModal({
      actionName: "ADD_MEMBER",
      data: { memberLookup: "", accessLevel: PROJECT_ACCESS_LEVEL_TYPE_VIEW },
    });
  };

  const onChangeMemberAccessLevel = useCallback(({ accessLevel, memberId }) => {
    updateProjectMember({ accessType: accessLevel, projectMemberId: memberId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDeleteIcon = useCallback(({ memberId }) => {
    deleteProjectMember({ projectMemberId: memberId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.projectTeamPageContainer}>
      <div className={classes.headerContainer}>
        <div className={classes.headerTitle}>{"Project Team Members"}</div>
        {permissions.canAddProjectMembers && (
          <Button onClick={onClickAddMember} className={classes.addMemberButton}>
            {"+ Add Member"}
          </Button>
        )}
      </div>

      <UsersFeedList
        isLoading={isLoading}
        users={projectMembers}
        onDeleteMember={onClickDeleteIcon}
        accessLevelOptions={allProjectAccessLevelOptions}
        onChangeMemberAccessLevel={onChangeMemberAccessLevel}
        canDeleteMembers={permissions.canDeleteProjectMembers}
        canChangeMemberAccessLevel={permissions.canChangeProjectMemberAccessLevel}
      />

      {showModal && (
        <ProjectTeamModal
          projectId={projectId}
          showModal={showModal}
          orgMembers={orgMembers}
          setShowModal={setShowModal}
          projectMembers={projectMembers}
          addProjectMember={addProjectMember}
          projectAccessType={projectAccessType}
        />
      )}
    </Box>
  );
};

export default ProjectTeam;

//Why does this code exist?
//This enables the end-user need to view, add and delete project members as well as invite new members to the project. It Also communicates with the server to perform actions related to project team members.
