import React from "react";

const DragIcon = React.memo(props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill="#706E6B"
        d="M7.69 1.539c.847 0 1.54.692 1.54 1.538 0 .846-.693 1.538-1.54 1.538a1.543 1.543 0 0 1-1.538-1.538c0-.846.693-1.538 1.539-1.538Zm4.616 0c.846 0 1.539.692 1.539 1.538 0 .846-.693 1.538-1.539 1.538a1.543 1.543 0 0 1-1.538-1.538c0-.846.692-1.538 1.538-1.538ZM7.691 6.154c.846 0 1.538.692 1.538 1.538 0 .847-.692 1.539-1.538 1.539a1.543 1.543 0 0 1-1.539-1.539c0-.846.693-1.538 1.539-1.538Zm4.615 0c.846 0 1.539.692 1.539 1.538 0 .847-.693 1.539-1.539 1.539a1.543 1.543 0 0 1-1.538-1.539c0-.846.692-1.538 1.538-1.538Zm-4.615 4.615c.846 0 1.538.693 1.538 1.539s-.692 1.538-1.538 1.538a1.543 1.543 0 0 1-1.539-1.538c0-.846.693-1.539 1.539-1.539Zm4.615 0c.846 0 1.539.693 1.539 1.539s-.693 1.538-1.539 1.538a1.543 1.543 0 0 1-1.538-1.538c0-.846.692-1.539 1.538-1.539Zm-4.615 4.616c.846 0 1.538.692 1.538 1.538 0 .846-.692 1.539-1.538 1.539a1.543 1.543 0 0 1-1.539-1.539c0-.846.693-1.538 1.539-1.538Zm4.615 0c.846 0 1.539.692 1.539 1.538 0 .846-.693 1.539-1.539 1.539a1.543 1.543 0 0 1-1.538-1.539c0-.846.692-1.538 1.538-1.538Z"
      />
    </svg>
  );
});

DragIcon.displayName = "DragIcon";
export default DragIcon;
