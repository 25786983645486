// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectContext_projectContextPageContainer_CCR4L{flex:1;padding:58px 40px;justify-content:flex-start}.ProjectContext_bodyContainer_Nz940{display:flex;flex-direction:column;align-items:center;width:100%;height:100%;max-width:450px}.ProjectContext_formContainer_ymqXV{width:100%;display:flex;flex-direction:column;gap:16px;margin:56px 0 28px 0}.ProjectContext_saveButtonContainer_OAUkL{align-self:flex-end;padding:4px 24px !important}", "",{"version":3,"sources":["webpack://./src/routes/routes/AppLayout/routes/Projects/routes/ProjectContext/components/ProjectContext.scss"],"names":[],"mappings":"AAAA,kDACE,MAAA,CACA,iBAAA,CACA,0BAAA,CAGF,oCACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CAGF,oCACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,oBAAA,CAGF,0CACE,mBAAA,CACA,2BAAA","sourcesContent":[".projectContextPageContainer {\r\n  flex: 1;\r\n  padding: 58px 40px;\r\n  justify-content: flex-start;\r\n}\r\n\r\n.bodyContainer {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  width: 100%;\r\n  height: 100%;\r\n  max-width: 450px;\r\n}\r\n\r\n.formContainer {\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 16px;\r\n  margin: 56px 0 28px 0;\r\n}\r\n\r\n.saveButtonContainer {\r\n  align-self: flex-end;\r\n  padding: 4px 24px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectContextPageContainer": "ProjectContext_projectContextPageContainer_CCR4L",
	"bodyContainer": "ProjectContext_bodyContainer_Nz940",
	"formContainer": "ProjectContext_formContainer_ymqXV",
	"saveButtonContainer": "ProjectContext_saveButtonContainer_OAUkL"
};
export default ___CSS_LOADER_EXPORT___;
