import get from "lodash/get";
import map from "lodash/map";
import { toast } from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";

import apiClient from "services/api/apiClient";
import queryClient from "services/api/queryClient";
import apiEndpoints, { dataCacheKeys } from "Constants/ApiEndpoints";

const updateMeeting = async ({ meeting, meetingId }) => {
  const payload = { params: { id: meetingId } };
  const response = await apiClient.put({
    payload,
    data: meeting,
    url: apiEndpoints.UPDATE_MEETING,
  });
  return response;
};

export const useUpdateMeeting = () => {
  return useMutation({
    mutationFn: updateMeeting,
    onSuccess: (response, variables) => {
      const meetingId = get(variables, "meetingId", "");
      return queryClient.invalidateQueries({
        queryKey: dataCacheKeys.meeting({ meetingId }),
      });
    },
    onSettled: (res, error) => {
      error
        ? toast.error("Something went wrong!")
        : toast.success("Meeting Purpose updated successfully!");
    },
  });
};

// PrepareQuestions
const addPrepareQuestion = async ({ question, meetingId }) => {
  const data = { question, meetingId };
  const response = await apiClient.post({
    data,
    url: apiEndpoints.ADD_PREPARED_QUESTION,
  });
  return response;
};

export const useAddPrepareQuestion = ({ meetingId }) => {
  return useMutation({
    mutationFn: addPrepareQuestion,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: dataCacheKeys.preparedQuestions({ meetingId }),
      });
    },
    onSettled: (res, error) => {
      error ? toast.error("Something went wrong!") : toast.success("Successfully Added!");
    },
  });
};

const updatePreparedQuestion = async ({
  questionId,
  meetingId,
  question,
  sequenceNumber = null,
}) => {
  const payload = { params: { id: questionId } };
  const data = sequenceNumber
    ? { question, meetingId, sequenceNumber }
    : { question, meetingId };
  const response = await apiClient.put({
    data,
    payload,
    url: apiEndpoints.UPDATE_PREPARED_QUESTION,
  });
  return response;
};

export const useUpdatePreparedQuestion = ({ meetingId }) => {
  return useMutation({
    mutationFn: updatePreparedQuestion,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: dataCacheKeys.preparedQuestions({ meetingId }),
      });
    },
    onSettled: (res, error) => {
      error
        ? toast.error("Something went wrong!")
        : toast.success("Question updated successfully!");
    },
  });
};

const updatePreparedQuestionsSequence = async ({ questions }) => {
  const data = map(questions, ({ id }, index) => ({
    questionId: id,
    newSequenceNumber: index,
  }));
  const response = await apiClient.put({
    data,
    url: apiEndpoints.UPDATE_PREPARED_QUESTIONS_SEQUENCE,
  });
  return response;
};

export const useUpdatePreparedQuestionsSequence = ({ meetingId }) => {
  return useMutation({
    mutationFn: updatePreparedQuestionsSequence,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: dataCacheKeys.preparedQuestions({ meetingId }),
      }),
    onSettled: (res, error) => {
      error
        ? toast.error("Something went wrong!")
        : toast.success("Sequence updated successfully!");
    },
  });
};

const deletePreparedQuestion = async ({ questionId }) => {
  const payload = { params: { id: questionId } };
  const response = await apiClient.delete({
    payload,
    url: apiEndpoints.DELETE_PREPARED_QUESTION,
  });
  return response;
};

export const useDeletePrepareQuestion = ({ meetingId }) => {
  return useMutation({
    mutationFn: deletePreparedQuestion,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: dataCacheKeys.preparedQuestions({ meetingId }),
      });
    },
    onSettled: (res, error) => {
      error
        ? toast.error("Something went wrong!")
        : toast.success("Question Deleted successfully!");
    },
  });
};

// AI Answered Questions
const updateAnsweredQuestions = async ({ answer, question, questionId }) => {
  const payload = { params: { id: questionId } };
  const data = { answer, question };
  const response = await apiClient.put({
    data,
    payload,
    url: apiEndpoints.UPDATE_ANSWERED_QUESTION,
  });
  return response;
};

export const useUpdateAnsweredQuestions = ({ meetingId }) => {
  return useMutation({
    mutationFn: updateAnsweredQuestions,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: dataCacheKeys.questionsAsked({ meetingId }),
      });
    },
    onSettled: (res, error) => {
      error
        ? toast.error("Something went wrong!")
        : toast.success("Answer updated successfully!");
    },
  });
};

const deleteAnsweredQuestions = async ({ questionId }) => {
  const payload = { params: { id: questionId } };
  const response = await apiClient.delete({
    payload,
    url: apiEndpoints.DELETE_ANSWERED_QUESTION,
  });
  return response;
};

export const useDeleteAnsweredQuestions = ({ meetingId }) => {
  return useMutation({
    mutationFn: deleteAnsweredQuestions,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: dataCacheKeys.questionsAsked({ meetingId }),
      });
    },
    onSettled: (res, error) => {
      error
        ? toast.error("Something went wrong!")
        : toast.success("Question Deleted successfully!");
    },
  });
};
