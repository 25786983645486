import React, { useMemo } from "react";
import { useRoutes } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";

import createRoutes from "routes";
import FullScreenLoader from "UIComponents/FullScreenLoader";

import "styles/globalStyles.scss";
import classes from "./AppContainer.scss";

const AppContainer = () => {
  const isMutating = useIsMutating();
  const nestedRoutes = useMemo(() => createRoutes(), []);
  const routes = useRoutes(nestedRoutes);

  return (
    <div className={classes.AppContainer}>
      {routes}
      {!!isMutating && <FullScreenLoader />}
    </div>
  );
};

export default AppContainer;

//Why does this code exist?
//This code is a fundamental component for the application's structure, routing, user experience, and security measures. It contributes significantly to the efficiency, usability, and robustness of the application.
