import React from "react";

const RocketIcon = React.memo(props => {
  const { fill } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props} fill={fill}>
      <path
        fill={fill}
        d="M3.862 7.279c0-.117-.147-.176-.234-.117L2.312 8.243c-.058.059-.058.146-.058.234v4.648c0 .292.41.438.672.234l2.134-1.667c.059-.058.088-.117.03-.204-.527-.79-1.053-2.105-1.228-4.21Zm2.835 4.355c.058.03.117.058.175.058H9.3c.058 0 .116-.029.175-.058.35-.234 1.754-1.403 1.754-5.379 0-1.841-.526-3.127-1.14-4.004C9.182.965 8.1.585 8.1.585S6.99.965 6.083 2.28c-.614.877-1.11 2.134-1.11 3.975 0 3.976 1.373 5.145 1.724 5.379ZM8.071 3.8c.964 0 1.754.79 1.754 1.754 0 .965-.79 1.754-1.754 1.754-.965 0-1.754-.79-1.754-1.754 0-.965.79-1.754 1.754-1.754Zm5.787 4.443-1.286-1.081c-.087-.088-.233-.03-.233.117-.176 2.104-.702 3.42-1.199 4.238a.18.18 0 0 0 .03.205l2.133 1.666c.263.204.643.058.643-.234V8.477c0-.088 0-.175-.088-.234Zm-3.946 4.765c-.058-.088-.146-.146-.233-.146H6.463c-.088 0-.204.058-.234.146a3.354 3.354 0 0 0-.409.906c-.088.35.205.702.585.702h3.332c.38 0 .672-.351.585-.702-.117-.438-.293-.76-.41-.906Z"
      />
    </svg>
  );
});

RocketIcon.displayName = "RocketIcon";
export default RocketIcon;
