import React from "react";

import classNames from "classnames";
import { backgroundColors } from "Constants/ColorConstants";
import classes from "./Chip.scss";

const { success, primary, danger, darkSubtle } = backgroundColors;

const variantToBgColorMapping = {
  danger,
  success,
  primary,
  subtle: darkSubtle,
  plain: "transparent",
};

const getChipContainerStyle = ({ variant, containerStyle }) => {
  const chipContainerStyle = {
    backgroundColor: variantToBgColorMapping[variant] || variantToBgColorMapping.primary,
  };
  return { ...chipContainerStyle, ...containerStyle };
};

const Chip = React.memo(props => {
  const { variant, children, className, containerStyle, isFullWidth = false } = props;

  const containerClass = classNames({
    [className]: !!className,
    [classes.container]: true,
    [classes.fullWidth]: isFullWidth,
  });
  const customStyle = getChipContainerStyle({ variant, containerStyle });

  return (
    <div className={containerClass} style={customStyle}>
      {children}
    </div>
  );
});

Chip.displayName = "Chip";
export default Chip;

//Why does this code exist?
//This code exists because it enables the surfacing of relevant informational visual indicators to the end-user, such as status of meetings or status of context provided.
