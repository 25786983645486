import React, { useMemo } from "react";

import map from "lodash/map";
import differenceBy from "lodash/differenceBy";
import { getProjectAccessLevelOptions } from "Constants/StringConstants";

import DialogBox from "UIComponents/DialogBox";
import classes from "./ProjectTeamModal.scss";

const modalBodyContainerStyle = {
  flex: 1,
};

const getFormInputProps = ({
  data,
  actionName,
  handleChange,
  lookUpMemberOptions,
  accessLevelOptions,
}) => {
  switch (actionName) {
    case "ADD_MEMBER": {
      const { memberLookup, accessLevel } = data;
      return [
        {
          id: "MEMBER_LOOKUP",
          formType: "lookup",
          name: "memberLookup",
          label: "Member Lookup",
          onChange: handleChange,
          options: lookUpMemberOptions,
          selectedOptionKey: memberLookup,
          customOverContainerStyle: { maxHeight: "115px" },
        },
        {
          id: "ACCESS_LEVEL",
          name: "accessLevel",
          formType: "dropdown",
          dropdownOverlay: "checklist",
          isFullWidth: false,
          onClickOptions: handleChange,
          overContainerStyle: { width: "220px" },
          customDropdownStyle: { width: "220px" },
          selectedOptionKey: accessLevel,
          options: accessLevelOptions,
        },
      ];
    }
    default:
      return [];
  }
};

const getDialogBoxProps = ({
  data,
  actionName,
  handleChange,
  onClickInvite,
  lookUpMemberOptions,
  accessLevelOptions,
}) => {
  const formInputProps = getFormInputProps({
    data,
    actionName,
    handleChange,
    lookUpMemberOptions,
    accessLevelOptions,
  });
  const actionNameToPropsMapping = {
    ADD_MEMBER: {
      title: "New Member",
      primaryButton: "Invite",
      formInputProps: formInputProps,
      onClickPrimaryButton: onClickInvite,
      bodyContainerStyle: modalBodyContainerStyle,
      modalContainerClass: classes.modalContainer,
      primaryButtonProps: { isDisabled: !data.memberLookup || !data.accessLevel },
    },
  };

  return actionNameToPropsMapping[actionName];
};

const ProjectTeamModal = props => {
  const {
    projectId,
    orgMembers,
    showModal,
    setShowModal,
    projectMembers,
    addProjectMember,
    projectAccessType,
  } = props;
  const { data, actionName } = showModal || {};

  const filteredMembers = differenceBy(orgMembers, projectMembers, "email");
  const lookUpMemberOptions = map(filteredMembers, ({ id, name }) => ({
    key: id,
    label: name,
  }));

  const accessLevelOptions = useMemo(
    () => getProjectAccessLevelOptions({ projectAccessType }),
    [projectAccessType]
  );

  const handleChange = value => {
    setShowModal(prevState => ({
      ...prevState,
      data: { ...prevState.data, ...value },
    }));
  };

  const onClickInvite = () => {
    const { accessLevel, memberLookup } = data;
    addProjectMember({ projectId, accessType: accessLevel, orgMemberId: memberLookup });
  };

  const dialogBoxProps = getDialogBoxProps({
    data,
    actionName,
    handleChange,
    onClickInvite,
    lookUpMemberOptions,
    accessLevelOptions,
  });

  return (
    <DialogBox showModal={showModal} setShowModal={setShowModal} {...dialogBoxProps} />
  );
};

export default ProjectTeamModal;
