import React from "react";
import { Outlet } from "react-router-dom";

import AppHeader from "Layouts/Partials/AppHeader";
import HomeSwitcher from "Layouts/Partials/HomeSwitcher";
import classes from "./AppLayout.scss";

const AppLayout = React.memo(() => {
  return (
    <div className={classes.appContainer}>
      <AppHeader />
      <div className={classes.bodyContainer}>
        <HomeSwitcher />
        <div className={classes.outletContainer}>
          <Outlet />
        </div>
      </div>
    </div>
  );
});

AppLayout.displayName = "AppLayout";
export default AppLayout;

//Why does this code exist?
//This code is needed to define the layout of the app.
