import { useQuery } from "@tanstack/react-query";

import apiClient from "services/api/apiClient";
import apiEndpoints, { dataCacheKeys } from "Constants/ApiEndpoints";
import { getCurrentOrgIdFromStorage } from "services/LocalStorage";

// Projects
const getAllProjects = async () => {
  const orgId = getCurrentOrgIdFromStorage();
  const payload = { query: { orgId } };
  const response = await apiClient.get({
    payload,
    url: apiEndpoints.GET_ALL_PROJECTS,
  });
  return response.projects;
};

export const useGetAllProjects = () => {
  const orgId = getCurrentOrgIdFromStorage();
  return useQuery({
    queryKey: dataCacheKeys.projects({ orgId }),
    queryFn: getAllProjects,
  });
};

// Single Project
const getSingleProject = async ({ projectId }) => {
  const payload = { params: { projectId } };
  const response = await apiClient.get({
    payload,
    url: apiEndpoints.GET_SINGLE_PROJECT,
  });
  return response.project;
};

export const useGetSingleProject = ({ projectId, params = {} }) => {
  return useQuery({
    queryKey: dataCacheKeys.project({ projectId }),
    queryFn: () => getSingleProject({ projectId }),
    ...params,
  });
};

// Meetings By ProjectId
const getMeetingsByProjectId = async ({ projectId }) => {
  const payload = { query: { project_id: projectId } };
  const response = await apiClient.get({
    payload,
    url: apiEndpoints.GET_MEETINGS_BY_PROJECT_ID,
  });
  return response.meetings;
};

export const useGetMeetingsByProjectId = ({ projectId }) => {
  return useQuery({
    queryKey: dataCacheKeys.projectMeetings({ projectId }),
    queryFn: () => getMeetingsByProjectId({ projectId }),
  });
};

// Project Members
const getProjectMembers = async ({ projectId }) => {
  const payload = { params: { projectId } };
  const projectMembers = await apiClient.get({
    payload,
    url: apiEndpoints.GET_PROJECT_MEMBERS,
  });
  return projectMembers;
};

export const useGetProjectMembers = ({ projectId }) => {
  return useQuery({
    queryKey: dataCacheKeys.projectMembers({ projectId }),
    queryFn: () => getProjectMembers({ projectId }),
  });
};
