import React from "react";

import classes from "./SingleCaptionBox.scss";
import Avatar from "UIComponents/Avatar/Avatar";

const SingleCaptionBox = React.memo(props => {
  const { imageUrl, clockTime, speaker, transcript } = props;

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.timeStamp}>{clockTime}</div>
        <Avatar width={32} url={imageUrl} className={classes.avatar} />
        <span className={classes.speaker}>{speaker || "Unidentified"}</span>
      </div>
      <div className={classes.row}>
        <div className={classes.caption}>{transcript}</div>
      </div>
    </div>
  );
});

SingleCaptionBox.displayName = "SingleCaptionBox";
export default SingleCaptionBox;
