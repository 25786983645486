import React from "react";
import PreMeetingContext from "./components";

const PreMeetingContextRoutes = () => {
  return {
    path: "preMeetingContext",
    element: <PreMeetingContext />,
    children: [],
  };
};

export default PreMeetingContextRoutes;

//Why does this code exist?
//Exports the route to enable the user to access preMeeting context.
