import React from "react";

const AvatarIcon = React.memo(() => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.92547 8.64C8.65838 8.16 8.4472 7.72 8.4472 7.24C8.4472 6.76 8.78509 6.32 9.20745 5.96C9.9677 5.36 10.3056 4.4 10.3056 3.4C10.3056 1.52 9.08075 0 6.8 0C4.51925 0 3.29441 1.52 3.29441 3.4C3.29441 4.4 3.6323 5.36 4.39255 5.96C4.81491 6.32 5.15279 6.76 5.15279 7.24C5.15279 7.72 4.94162 8.16 3.67453 8.64C1.81615 9.36 0.042236 10.16 0 11.68C0.0844721 12.72 0.929192 13.6 1.98509 13.6H11.6994C12.7553 13.6 13.6 12.72 13.6 11.72C13.5578 10.2 11.7839 9.36 9.92547 8.64Z"
        fill="black"
      />
    </svg>
  );
});

AvatarIcon.displayName = "AvatarIcon";
export default AvatarIcon;
