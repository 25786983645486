import React, { useMemo } from "react";

import get from "lodash/get";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "modules/commonSelectors";
import { useLogout } from "routes/modules/loginModules";

import Avatar from "UIComponents/Avatar";
import Dropdown from "UIComponents/Dropdown";
// import SwiftBaLogo from "SvgComponents/SwiftBaLogo";
import classes from "./AppHeader.scss";

const dropdownOverContainerStyle = {
  top: "52px",
  right: "0",
  width: "200px",
};

const getAvatarDropdownOptions = ({ userName, onClickDropdownOptions }) => {
  return [
    {
      key: "USER_NAME",
      label: userName,
      value: userName,
      type: "info",
    },
    {
      key: "SETTINGS",
      label: "Settings",
      value: "settings",
      type: "menuItem",
      onClick: onClickDropdownOptions,
    },
    {
      key: "LOGOUT",
      label: "Logout",
      value: "Logout",
      type: "menuItem",
      onClick: onClickDropdownOptions,
    },
  ];
};

const AppHeader = React.memo(() => {
  const navigate = useNavigate();
  const { mutateAsync: logout } = useLogout();
  const user = useSelector(getUserInfo);

  const userName = get(user, "name", "");
  const imageUrl = get(user, "image", "");

  const gotoHome = () => {
    navigate("home");
  };

  const extensionId = "blabdokikbfcibadddkfhfpennejpjnd";
  const sendCookieToExtension = () => {
    const message = { action: "logout" };
    if (window?.chrome) {
      window.chrome.runtime.sendMessage(extensionId, message, response => {
        if (window.chrome.runtime.lastError) console.error(window.chrome.runtime.lastError);
        console.log("Message sent to extension:", response);
      });
    }
  };

  const handleLogout = async () => {
    await logout();
    sendCookieToExtension();
    navigate("/");
  };

  const onClickDropdownOptions = ({ key, value }) => {
    switch (key) {
      case "SETTINGS": {
        navigate(value);
        break;
      }
      case "LOGOUT": {
        handleLogout();
        break;
      }
      default: {
        return;
      }
    }
  };

  const avatarDropdownOptions = useMemo(
    () => getAvatarDropdownOptions({ userName, onClickDropdownOptions }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userName]
  );

  return (
    <div className={classes.headerContainer}>
      <span className={classes.swiftBaLogo} onClick={gotoHome}>
        {/* <SwiftBaLogo /> */}
      </span>

      <Dropdown
        options={avatarDropdownOptions}
        overContainerStyle={dropdownOverContainerStyle}
      >
        <Avatar url={imageUrl} />
      </Dropdown>
    </div>
  );
});

AppHeader.displayName = "AppHeader";
export default AppHeader;

//Why does this code exist?
//The creates the header of the application including a logo, a message to alert the user of something if needed, and the drop down menu for access to settings and log out.
