import React from "react";
import AppLayoutRoutes from "./routes/AppLayout";
import PageNotFoundRoutes from "./routes/PageNotFound";

import ProtectedRoutes from "utils/ProtectedRoutes";
import withAsyncRouteLoading from "Hocs/withAsyncRouteLoading";

const Login = withAsyncRouteLoading(() =>
  import(/* webpackChunkName: "login" */ "./components")
);

const createRoutes = () => {
  return [
    {
      path: "/",
      element: <ProtectedRoutes component={Login} source={"login"} />,
    },
    AppLayoutRoutes(),
    PageNotFoundRoutes(),
  ];
};

export default createRoutes;

//Why does this code exist?
//This makes the two modules available for export.
