import React from "react";

import classNames from "classnames";
import { backgroundColors } from "Constants/ColorConstants";

import classes from "./Button.scss";

const { white, success, primary, danger, bgSubtleButton } = backgroundColors;

const variantToBgColorMapping = {
  danger,
  success,
  primary,
  secondary: white,
  plain: "transparent",
  subtle: bgSubtleButton,
};

const sizeToHeightMapping = {
  xl: "52px",
  lg: "44px",
  md: "32px",
  sm: "28px",
  xs: "24px",
};

const getButtonContainerStyle = ({ size, variant, isDisabled, containerStyle }) => {
  let buttonContainerStyle = {
    height: sizeToHeightMapping[size] || sizeToHeightMapping.md,
    backgroundColor: variantToBgColorMapping[variant] || sizeToHeightMapping.primary,
  };

  if (isDisabled)
    buttonContainerStyle = {
      ...buttonContainerStyle,
      backgroundColor: backgroundColors.disabled,
      cursor: "not-allowed",
    };
  return { ...buttonContainerStyle, ...containerStyle };
};

const getChildComponent = ({ Icon, children }) => {
  return (
    <>
      <Icon /> {children}
    </>
  );
};

const Button = React.memo(props => {
  const {
    size = "md",
    variant = "primary",
    children,
    onClick = () => {},
    Icon = null,
    className,
    isDisabled = false,
    isIconButton,
    containerStyle = {},
    ...rest
  } = props;

  const buttonContainerClass = classNames(
    { [className]: !!className },
    { [classes.buttonContainer]: true },
    { [classes.iconButtonContainer]: isIconButton }
  );

  const childComponent = Icon ? getChildComponent({ Icon, children }) : children;

  const buttonContainerStyle = getButtonContainerStyle({
    size,
    variant,
    isDisabled,
    containerStyle,
  });

  const handleOnClick = event => {
    event.preventDefault();
    if (isDisabled) {
      event.stopPropagation();
      return;
    }
    onClick(event);
  };

  return (
    <button
      type={"button"}
      onClick={handleOnClick}
      style={buttonContainerStyle}
      className={buttonContainerClass}
      {...rest}
    >
      {childComponent}
    </button>
  );
});

Button.displayName = "Button";
export default Button;

//Why does this code exist?
//This enables the end-user to click a button to initiate an action, such as deleting a project.
