import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";

import { setOrgInitialState } from "modules/orgReducer";
import { setUserInitialState } from "modules/userReducer";

import apiClient from "services/api/apiClient";
import apiEndpoints from "Constants/ApiEndpoints";
import queryClient from "services/api/queryClient";
import { clearLocalStorage, setIsTokenValid } from "services/LocalStorage";

const login = async ({ jwtToken }) => {
  const data = { idToken: jwtToken };
  const response = await apiClient.post({ data, url: apiEndpoints.LOGIN });
  return response.cookie;
};

export const useLogin = () => {
  return useMutation({
    mutationFn: login,
    onSuccess: data => {
      if (data) setIsTokenValid({ value: true });
      toast.success("Successfully logged in!");
    },
    onError: () => toast.error("Something went wrong!"),
  });
};

const logout = async () => {
  const response = await apiClient.post({ url: apiEndpoints.LOGOUT });
  return response;
};

export const useLogout = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: logout,
    onSuccess: data => {
      if (data) {
        clearLocalStorage();
        dispatch(setOrgInitialState());
        dispatch(setUserInitialState());
      }
      toast.success("Successfully Logged Out!");
      queryClient.clear();
    },
    onError: () => toast.error("Something went wrong!"),
  });
};
