// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToggleButton_buttonsContainer__JaKh{display:flex;position:relative;flex-direction:column;background-color:#d9d9d9;justify-content:space-between;width:32px;height:124px;border-radius:28px;box-shadow:0px 4px 4px rgba(0,0,0,.05);cursor:pointer}.ToggleButton_indicator_EVowm{display:flex;position:absolute;top:0;left:0;width:32px;height:62px;background-color:#23abc8;border-radius:28px;transition:transform .3s ease}.ToggleButton_button_gk_3e{display:flex;z-index:3;width:100%;height:100%;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/UIComponents/ToggleButton/ToggleButton.scss"],"names":[],"mappings":"AAAA,qCACE,YAAA,CACA,iBAAA,CACA,qBAAA,CACA,wBAAA,CACA,6BAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sCAAA,CACA,cAAA,CAGF,8BACE,YAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,wBAAA,CACA,kBAAA,CACA,6BAAA,CAGF,2BACE,YAAA,CACA,SAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".buttonsContainer {\r\n  display: flex;\r\n  position: relative;\r\n  flex-direction: column;\r\n  background-color: #d9d9d9;\r\n  justify-content: space-between;\r\n  width: 32px;\r\n  height: 124px;\r\n  border-radius: 28px;\r\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);\r\n  cursor: pointer;\r\n}\r\n\r\n.indicator {\r\n  display: flex;\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 32px;\r\n  height: 62px;\r\n  background-color: #23abc8;\r\n  border-radius: 28px;\r\n  transition: transform 0.3s ease;\r\n}\r\n\r\n.button {\r\n  display: flex;\r\n  z-index: 3;\r\n  width: 100%;\r\n  height: 100%;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n//Why does this code exist?\r\n//This code styles the main toggle slider button.\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsContainer": "ToggleButton_buttonsContainer__JaKh",
	"indicator": "ToggleButton_indicator_EVowm",
	"button": "ToggleButton_button_gk_3e"
};
export default ___CSS_LOADER_EXPORT___;
