import get from "lodash/get";

export const getUserInfo = state => {
  return get(state, "user", "");
};

export const getOrgInfo = state => {
  return get(state, "org", "");
};

export const getOrgId = state => {
  return get(state, "org.id", "");
};

export const getUserOrgAccessLevel = state => {
  return get(state, "org.accessType", "");
};
