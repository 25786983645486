import { combineReducers } from "redux";

import orgReducer from "modules/orgReducer";
import userReducer from "modules/userReducer";

const staticReducers = {
  user: userReducer,
  org: orgReducer,
};

const createRootReducer = asyncReducers => {
  return combineReducers({ ...staticReducers, ...asyncReducers });
};

export default createRootReducer;
