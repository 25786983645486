import React, { useEffect, useState } from "react";

import map from "lodash/map";
import { useOutletContext } from "react-router-dom";

import { useUpdateProject } from "Projects/modules/ProjectsMutations";

import Box from "UIComponents/Box";
import Chip from "UIComponents/Chip";
import Button from "UIComponents/Button";
import CheckLogo from "SvgComponents/CheckLogo";
import InputTextArea from "UIComponents/InputTextArea";
import InputTextField from "UIComponents/InputTextField";
import classes from "./ProjectContext.scss";

const getFormInputs = ({ formData, permissions }) => {
  const { businessDescription, industry } = formData;
  return [
    {
      id: "BUSINESS_DESCRIPTION",
      label: "Business Description",
      name: "businessDescription",
      formType: "textArea",
      disabled: !permissions.canEditProject,
      value: businessDescription,
      autoFocus: true,
      minRows: 8,
      maxRows: 8,
    },
    {
      id: "INDUSTRY",
      label: "Industry",
      name: "industry",
      formType: "textField",
      type: "text",
      disabled: !permissions.canEditProject,
      value: industry,
    },
  ];
};

const ProjectContextForm = props => {
  const { formData, handleChange, permissions } = props;
  const formInputs = getFormInputs({ formData, permissions });

  return map(formInputs, ({ id, formType, ...restProps }) => {
    return formType === "textField" ? (
      <InputTextField key={id} {...restProps} updateInputField={handleChange} />
    ) : (
      <InputTextArea key={id} {...restProps} updateInputField={handleChange} />
    );
  });
};

const initialFormData = {
  industry: "",
  businessDescription: "",
};

const ProjectContext = () => {
  const {
    project,
    projectId,
    permissions,
    filledIndustry,
    filledDescription,
    isProjectContextFilled,
  } = useOutletContext();
  const [formData, setFormData] = useState(initialFormData);
  const { mutate: updateProject } = useUpdateProject();

  useEffect(() => {
    setFormData({
      industry: filledIndustry,
      businessDescription: filledDescription,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { industry, businessDescription } = formData;

  const isSaveButtonDisabled =
    !industry ||
    !businessDescription ||
    (industry === filledIndustry && businessDescription === filledDescription);

  const handleChange = value => {
    setFormData(prevState => ({ ...prevState, ...value }));
  };

  const onClickSave = () => {
    updateProject({
      projectId,
      project: { ...project, businessIndustry: industry, businessDescription },
    });
  };

  return (
    <Box className={classes.projectContextPageContainer}>
      <form className={classes.bodyContainer}>
        {isProjectContextFilled ? (
          <CheckLogo />
        ) : (
          <Chip variant={"danger"} isFullWidth={false}>
            {"Incomplete"}
          </Chip>
        )}

        <div className={classes.formContainer}>
          <ProjectContextForm
            formData={formData}
            onClickSave={onClickSave}
            handleChange={handleChange}
            permissions={permissions}
          />
        </div>
        <Button
          type={"submit"}
          onClick={onClickSave}
          isDisabled={isSaveButtonDisabled}
          className={classes.saveButtonContainer}
        >
          {"Save"}
        </Button>
      </form>
    </Box>
  );
};

export default ProjectContext;

//Why does this code exist?
//The end-user needs to be able to provide project context information and they need to be informed of a complete status when it is provided. This code enables that functionality and also updates the data to the redux store.
