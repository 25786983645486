import React from "react";

import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";

import CheckIcon from "SvgComponents/CheckIcon";
import { useDetectOutsideClick } from "Hooks/useDetectOutsideClick";
import classes from "./Dropdown.scss";

const getOptionClass = ({ type, className, overlay }) => {
  const optionClass = classNames(
    { [classes.option]: true },
    { [className]: !!className },
    { [classes.info]: type === "info" },
    { [classes.menuItem]: type === "menuItem" },
    { [classes.checklistMenuItem]: overlay === "checklist" },
    { [classes.actionOption]: type === "action" }
  );
  return optionClass;
};

const Dropdown = React.memo(props => {
  const {
    name,
    children,
    overlay,
    isDisabled,
    options = [],
    onClickOptions,
    selectedOptionKey,
    overContainerStyle = {},
    customTriggerState = false,
    customDropdownStyle = {},
    customOnCloseFunction = () => {},
  } = props;
  const [triggerRef, isDialogOpen, setIsDialogOpen] = useDetectOutsideClick(false);

  const overlayContainerClass = classNames(
    { [classes.overlayContainer]: true },
    { [classes.active]: isDialogOpen || customTriggerState }
  );

  const onClickTrigger = () => {
    if (isDisabled) return;
    customOnCloseFunction();
    setIsDialogOpen(prevState => !prevState);
  };

  const onClickCheckListOptions = ({
    type,
    value,
    label,
    onClick,
    isSelected,
    contextOption,
  }) => {
    if (isDisabled || isSelected || type == "info") return;
    if (isEmpty(onClick)) onClickOptions(value, label, contextOption);
    else onClick();
  };

  return (
    <div className={classes.menuContainer} style={customDropdownStyle}>
      <span ref={triggerRef} onClick={onClickTrigger} className={classes.menuTrigger}>
        {children}
      </span>

      <div className={overlayContainerClass} style={overContainerStyle}>
        <div className={classes.optionsContainer}>
          {map(
            options,
            ({
              key,
              label,
              value,
              className,
              customStyle,
              contextOption = {},
              type = "menuItem",
              onClick = () => {},
              component: Component = null,
            }) => {
              const optionClass = getOptionClass({ type, overlay, className });
              const isSelected = selectedOptionKey == key;
              return overlay === "checklist" ? (
                <span
                  key={key}
                  style={customStyle}
                  className={optionClass}
                  onClick={() =>
                    onClickCheckListOptions({
                      type,
                      label,
                      onClick,
                      isSelected,
                      contextOption,
                      value: { [name]: key },
                    })
                  }
                >
                  {isSelected ? <CheckIcon /> : <span style={{ width: "16px" }} />}
                  {Component ? <Component /> : label}
                </span>
              ) : (
                <span
                  key={key}
                  style={customStyle}
                  className={optionClass}
                  onClick={() => onClick({ key, value })}
                >
                  {Component ? <Component /> : label}
                </span>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
});

Dropdown.displayName = "Dropdown";
export default Dropdown;

//Why does this code exist?
//This code enables provides the fundamental functinality of a dropdown so the user can make selections in the app.
