import React from "react";
import withAsyncRouteLoading from "Hocs/withAsyncRouteLoading";

const Billing = withAsyncRouteLoading(() =>
  import(/* webpackChunkName: "billing" */ "./components")
);

const BillingRoutes = () => {
  return {
    path: "billing",
    element: <Billing documentTitle={"Billing"} />,
    children: [],
  };
};

export default BillingRoutes;

//Why does this code exist?
//This create the route for the user to access their plans.
