import { useState, useEffect, useRef } from "react";

export const useDetectOutsideClick = initialState => {
  const triggerRef = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(initialState);

  useEffect(() => {
    const handleClickOutside = event => {
      if (!triggerRef?.current?.contains(event.target)) setIsDialogOpen(false);
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return [triggerRef, isDialogOpen, setIsDialogOpen];
};
