import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import withAsyncRouteLoading from "Hocs/withAsyncRouteLoading";

import MeetingsRoutes from "./routes/Meeting";
import ProjectTeamRoutes from "./routes/ProjectTeam";
import ProjectContextRoutes from "./routes/ProjectContext";

const ProjectLayout = withAsyncRouteLoading(() =>
  import(/* webpackChunkName: "projectLayout" */ "Layouts/ProjectLayout/ProjectLayout")
);
const Projects = withAsyncRouteLoading(() =>
  import(/* webpackChunkName: "projects" */ "./components")
);
const Meetings = withAsyncRouteLoading(() =>
  import(/* webpackChunkName: "meetings" */ "./routes/Meeting/components")
);

const ProjectsRoutes = () => {
  return {
    path: "projects",
    element: <Outlet />,
    children: [
      { index: true, element: <Projects documentTitle={"Projects"} /> },
      {
        path: ":projectId",
        element: <ProjectLayout source={"projects"} />,
        children: [
          {
            index: true,
            element: <Navigate to="meetings" replace />,
          },
          {
            path: "meetings",
            element: <Meetings documentTitle={"Meetings"} />,
          },
          ProjectTeamRoutes(),
          ProjectContextRoutes(),
        ],
      },
      MeetingsRoutes(),
    ],
  };
};

export default ProjectsRoutes;

//Why does this code exist?
//This enables the routes for projects, so that the end user can access project records.
