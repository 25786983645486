import React, { useEffect } from "react";

import Modal from "react-modal";
import classNames from "classnames";

import classes from "./UIModal.scss";

const UIModal = React.memo(props => {
  const {
    isOpen = false,
    children,
    customStyle = { content: {}, overlay: {} },
    contentRef,
    modalContent = "",
    isFullScreen = false,
    overlayContainer = "",
    closeOnOutSideClick,
    shouldCloseOnOverlayClick = true,
  } = props;

  const onRequestClose = event => {
    const { onRequestClose = null } = props;
    if (onRequestClose) {
      onRequestClose(event);
    }
  };

  const escapePress = event => {
    const code = event.keyCode ? event.keyCode : event.which;
    if (code === 27) {
      event.stopPropagation();
      onRequestClose(event);
    }
  };

  const onOutSideClick = event => {
    if (contentRef && closeOnOutSideClick && !contentRef.contains(event.target)) {
      event.stopPropagation();
      onRequestClose(event);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escapePress);
    document.addEventListener("mousedown", onOutSideClick);
    return () => {
      document.removeEventListener("keydown", escapePress);
      document.removeEventListener("mousedown", onOutSideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const overlayContainerClass = classNames(
    { [classes.overlayContainer]: true },
    { [overlayContainer]: !!overlayContainer },
    { [classes.noFullscreenOverlayContainer]: !isFullScreen }
  );

  const modalContentClass = classNames(
    { [classes.modalContent]: true },
    { [modalContent]: !!modalContent },
    { [classes.fullScreenModalContent]: isFullScreen }
  );

  const childrenClasses = classNames(
    { [classes.modalChildren]: true },
    { [classes.modalChildrenResize]: isFullScreen }
  );

  return (
    <Modal
      isOpen={isOpen}
      style={customStyle}
      ariaHideApp={false}
      className={modalContentClass}
      shouldFocusAfterRender={false}
      onRequestClose={onRequestClose}
      overlayClassName={overlayContainerClass}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {children && <div className={childrenClasses}>{children}</div>}
    </Modal>
  );
});

UIModal.displayName = "UIModal";
export default UIModal;

//Why does this code exist?
//This code enables popups for the user to confirm certain actions such as record deletes.
