import React from "react";
import AppLayout from "Layouts/AppLayout";

const AppLayoutPage = () => {
  return <AppLayout />;
};

export default AppLayoutPage;

//Why does this code exist?
//Unknown
