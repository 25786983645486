import React, { useState } from "react";

import classNames from "classnames";
import Textarea from "react-textarea-autosize";

import UILabel from "UIComponents/UILabel";
import classes from "./InputTextArea.scss";

const getCustomStyle = ({ textAreaStyles, disabled }) => {
  let updatedInputStyle = { ...textAreaStyles };
  if (disabled) {
    updatedInputStyle = { ...updatedInputStyle, cursor: "not-allowed" };
  }
  return updatedInputStyle;
};

const InputTextArea = React.memo(props => {
  const {
    name,
    value,
    autoFocus,
    updateInputField,
    minRows,
    maxRows,
    disabled,
    className,
    placeholder,
    textAreaStyles,
    onPaste,
    label,
  } = props;

  const [keysDown, setKeysDown] = useState({});
  const showLabel = !!label;

  const handleKeyDown = event => {
    const { allowShiftEnter, onEnterPress, onKeyDown } = props;
    const kd = keysDown;
    kd[event.keyCode] = true;
    setKeysDown(kd);
    if (kd[16] && kd[13] && allowShiftEnter) {
      event.preventDefault();
      const updateVal = `${event.target.value}\r`;
      kd[event.keyCode] = false;
      setKeysDown(kd);
      updateValue(updateVal);
    } else if (event.key === "Enter" && onEnterPress) {
      event.preventDefault();
      onEnterPress(event.target.value);
      setKeysDown(kd);
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const handleKeyUp = event => {
    const { onEnterPress, onKeyUp } = props;
    if (onEnterPress) {
      const kd = keysDown;
      kd[event.keyCode] = false;
      setKeysDown({ [event.keyCode]: false });
    }
    if (onKeyUp) {
      onKeyUp(event);
    }
  };

  const updateValue = event => {
    const value = event.target.value;
    if (updateInputField) {
      if (name) {
        updateInputField({ [name]: value });
      } else {
        updateInputField(value);
      }
    }
  };

  const inputText = classNames({
    [className]: !!className,
    [classes.inputText]: true,
    [classes.inputTextDisable]: disabled,
  });

  const customStyle = getCustomStyle({ disabled, textAreaStyles });

  return (
    <div className={classes.container}>
      {showLabel && <UILabel label={label} />}
      <Textarea
        name={name}
        type={"text"}
        minRows={minRows}
        maxRows={maxRows}
        onPaste={onPaste}
        disabled={disabled}
        style={customStyle}
        autoFocus={autoFocus}
        className={inputText}
        onKeyUp={handleKeyUp}
        onChange={updateValue}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        value={value ? value : ""}
      />
    </div>
  );
});

InputTextArea.displayName = "InputTextArea";
export default InputTextArea;

//Why does this code exist?
//This code enables the user to input text values throughout the application for multiple lines of text.
