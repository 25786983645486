import React from "react";
import PreparedQuestions from "./components";

const PreparedQuestionsRoutes = () => {
  return {
    path: "preparedQuestions",
    element: <PreparedQuestions />,
    children: [],
  };
};

export default PreparedQuestionsRoutes;
