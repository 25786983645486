const apiEndpoints = {
  // BASE_URL: process.env.REACT_APP_BACKEND_BASE_URL,
  // BASE_URL: "http://localhost:8080/",
  BASE_URL: "https://api.chatapt.co/swifthr/",
  // General APIs
  LOGIN: "session/init",
  LOGOUT: "session/logout",

  // User APIs
  GET_USER_DETAILS: "users",
  UPDATE_ACTIVE_ORG_ID: "users/update",

  // Organizations APIs
  ADD_ORGANIZATION: "organisations",
  GET_ORGANIZATIONS: "organisations",
  UPDATE_ORGANIZATION: "organisations/:id",
  DELETE_ORGANIZATION: "organisations/:id",
  GET_ORGANIZATION_DETAILS: "organisations/:organisationId",

  // Organization Members APIs
  ADD_ORG_MEMBER: "org-members/members",
  UPDATE_ORG_MEMBER: "org-members/members/:id",
  DELETE_ORG_MEMBER: "org-members/members/:id",
  GET_ORG_MEMBERS: "org-members/organisations/:orgId/members",

  // Projects APIs
  ADD_PROJECT: "projects",
  GET_ALL_PROJECTS: "projects",
  UPDATE_PROJECT: "projects/:projectId",
  DELETE_PROJECT: "projects/:projectId",
  GET_SINGLE_PROJECT: "projects/:projectId",

  // Project Members APIs
  ADD_PROJECT_MEMBER: "project-members",
  UPDATE_PROJECT_MEMBER: "project-members/:id",
  DELETE_PROJECT_MEMBER: "project-members/:id",
  GET_PROJECT_MEMBERS: "project-members/:projectId",

  // Meetings APIs
  ADD_MEETING: "meetings",
  UPDATE_MEETING: "meetings/:id",
  DELETE_MEETING: "meetings/:id",
  GET_SINGLE_MEETING: "meetings/:id",
  GET_MEETINGS_BY_PROJECT_ID: "meetings",
  GET_MEETING_TRANSCRIPT: "meetings/:meetingId/transcript",
  GET_MEETINGS_BY_ORG_ID: "organisations/meetings/:organisationId",

  // Prepared Questions APIs
  ADD_PREPARED_QUESTION: "prepared-questions",
  DELETE_PREPARED_QUESTION: "prepared-questions/:id",
  UPDATE_PREPARED_QUESTION: "prepared-questions/update/:id",
  GET_ALL_PREPARED_QUESTIONS: "prepared-questions/meeting/:meetingId",
  UPDATE_PREPARED_QUESTIONS_SEQUENCE: "prepared-questions/update-sequence",

  // Answered Questions APIs
  UPDATE_ANSWERED_QUESTION: "answered-questions/:id",
  DELETE_ANSWERED_QUESTION: "answered-questions/:id",
  GET_ALL_ANSWERED_QUESTIONS: "answered-questions/:id/askAllQuestions",

  // Stripe Subscription APIs
  GET_PAYMENT_LINK: "stripe/generatePaymentLink/:id",
};

export const dataCacheKeys = {
  user: ["user"],
  organizations: ["organizations"],
  organization: ({ orgId }) => ["organization", orgId],
  projects: ({ orgId }) => ["organization", orgId, "projects"],
  allMeetings: ({ orgId }) => ["organization", orgId, "meetings"],
  orgMembers: ({ orgId }) => ["organization", orgId, "orgMembers"],
  paymentLink: ({ orgId }) => ["organization", orgId, "paymentLink"],

  project: ({ projectId }) => ["project", projectId],
  projectMeetings: ({ projectId }) => ["project", projectId, "meetings"],
  projectMembers: ({ projectId }) => ["project", projectId, "projectMembers"],

  meeting: ({ meetingId }) => ["meeting", meetingId],
  transcript: ({ meetingId }) => ["meeting", meetingId, "transcript"],
  questionsAsked: ({ meetingId }) => ["meeting", meetingId, "questionsAsked"],
  preparedQuestions: ({ meetingId }) => ["meeting", meetingId, "preparedQuestions"],
};

export default apiEndpoints;
