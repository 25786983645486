import React, { useEffect, useState } from "react";

import map from "lodash/map";
import { useOutletContext } from "react-router-dom";
import { useUpdateMeeting } from "Meetings/modules/MeetingsMutations";

import Box from "UIComponents/Box";
import Chip from "UIComponents/Chip";
import Button from "UIComponents/Button";
import CheckLogo from "SvgComponents/CheckLogo";
import InputTextArea from "UIComponents/InputTextArea";
import classes from "./PreMeetingContext.scss";

const getFormInputs = ({ formData }) => {
  const { meetingPurpose } = formData;
  return [
    {
      id: "MEETING_PURPOSE",
      label: "Meeting Purpose",
      name: "meetingPurpose",
      formType: "textArea",
      value: meetingPurpose,
      autoFocus: true,
      minRows: 8,
      maxRows: 8,
    },
  ];
};

const PreMeetingContextForm = props => {
  const { formData, handleChange } = props;
  const formInputs = getFormInputs({ formData });

  return map(formInputs, ({ id, ...restProps }) => (
    <InputTextArea key={id} {...restProps} updateInputField={handleChange} />
  ));
};

const initialFormData = {
  meetingPurpose: "",
};

const PreMeetingContext = () => {
  const { meeting, meetingId, filledMeetingPurpose } = useOutletContext();

  const [formData, setFormData] = useState(initialFormData);
  const { mutate: updateMeeting } = useUpdateMeeting();

  const { meetingPurpose } = formData;
  const isPreMeetingContextFilled = !!filledMeetingPurpose;
  const isSaveButtonDisabled = !meetingPurpose || meetingPurpose === filledMeetingPurpose;

  useEffect(() => {
    setFormData({ meetingPurpose: filledMeetingPurpose });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = value => {
    setFormData(prevState => ({ ...prevState, ...value }));
  };

  const onClickSave = () => {
    updateMeeting({
      meetingId,
      meeting: { ...meeting, purpose: meetingPurpose },
    });
  };

  return (
    <Box className={classes.preMeetingContextPageContainer}>
      <form className={classes.bodyContainer}>
        {isPreMeetingContextFilled ? (
          <CheckLogo />
        ) : (
          <Chip variant={"danger"} isFullWidth={false}>
            {"Incomplete"}
          </Chip>
        )}
        <div className={classes.formContainer}>
          <PreMeetingContextForm formData={formData} handleChange={handleChange} />
        </div>
        <Button
          type={"submit"}
          onClick={onClickSave}
          isDisabled={isSaveButtonDisabled}
          className={classes.saveButtonContainer}
        >
          {"Save"}
        </Button>
      </form>
    </Box>
  );
};

export default PreMeetingContext;

//Why does this code exist?
//The end-user needs the ability to input pre-meeting context about their meeting so the models can generate relevant outputs during the meeting. This code enables them to input that data and receive visual confirmation.
