import React, { useEffect, useRef, useState } from "react";

import map from "lodash/map";
import findIndex from "lodash/findIndex";

import { backgroundColors } from "Constants/ColorConstants";
import classes from "./ToggleButton.scss";

const getIndicatorStyles = ({ activeValueIndex }) => {
  return { transform: `translateY(${activeValueIndex * 100}%)` };
};

const getActiveValueIndex = ({ buttonsConfig, initialActiveValue }) => {
  return findIndex(buttonsConfig, { value: initialActiveValue });
};

const ToggleButton = React.memo(props => {
  const { buttonsConfig, initialActiveValue, onSwitch } = props;
  const initialActiveValueIndex = getActiveValueIndex({
    buttonsConfig,
    initialActiveValue,
  });

  const indicatorRef = useRef(null);
  const [activeValueIndex, setActiveValueIndex] = useState(initialActiveValueIndex);

  useEffect(() => {
    setActiveValueIndex(initialActiveValueIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialActiveValue]);

  const indicatorStyles = getIndicatorStyles({ activeValueIndex });

  const onToggleSwitch =
    ({ index, value }) =>
    event => {
      if (indicatorRef.current.contains(event.target)) return;
      setActiveValueIndex(index);
      onSwitch({ activeValue: value });
    };

  return (
    <div className={classes.buttonsContainer}>
      <div ref={indicatorRef} style={indicatorStyles} className={classes.indicator} />
      {map(buttonsConfig, ({ id, label, value, Component }, index) => {
        const isActive = index === activeValueIndex;
        const fill = isActive ? backgroundColors.white : backgroundColors.darkSubtle;
        return (
          <div
            key={id}
            className={classes.button}
            onClick={onToggleSwitch({ index, value })}
          >
            {Component ? <Component fill={fill} /> : label}
          </div>
        );
      })}
    </div>
  );
});

ToggleButton.displayName = "ToggleButton";
export default ToggleButton;

//Why does this code exist?
//This enables the user to navtiagte the main sections of the app "home" and "projects" with the use of a slider button.
