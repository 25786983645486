// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreMeetingContext_preMeetingContextPageContainer_CdbrM{flex:1;padding:58px 40px;justify-content:flex-start;height:75.2vh}.PreMeetingContext_bodyContainer_ocPaO{display:flex;flex-direction:column;align-items:center;width:100%;height:100%;max-width:450px}.PreMeetingContext_formContainer_Yhr5B{width:100%;display:flex;flex-direction:column;margin:56px 0 28px 0}.PreMeetingContext_saveButtonContainer_KHwoP{align-self:flex-end;padding:4px 24px !important}", "",{"version":3,"sources":["webpack://./src/routes/routes/AppLayout/routes/Projects/routes/Meeting/routes/PreMeetingContext/components/PreMeetingContext.scss"],"names":[],"mappings":"AAAA,wDACE,MAAA,CACA,iBAAA,CACA,0BAAA,CACA,aAAA,CAGF,uCACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CAGF,uCACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,oBAAA,CAGF,6CACE,mBAAA,CACA,2BAAA","sourcesContent":[".preMeetingContextPageContainer {\r\n  flex: 1;\r\n  padding: 58px 40px;\r\n  justify-content: flex-start;\r\n  height: 75.2vh;\r\n}\r\n\r\n.bodyContainer {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  width: 100%;\r\n  height: 100%;\r\n  max-width: 450px;\r\n}\r\n\r\n.formContainer {\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin: 56px 0 28px 0;\r\n}\r\n\r\n.saveButtonContainer {\r\n  align-self: flex-end;\r\n  padding: 4px 24px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preMeetingContextPageContainer": "PreMeetingContext_preMeetingContextPageContainer_CdbrM",
	"bodyContainer": "PreMeetingContext_bodyContainer_ocPaO",
	"formContainer": "PreMeetingContext_formContainer_Yhr5B",
	"saveButtonContainer": "PreMeetingContext_saveButtonContainer_KHwoP"
};
export default ___CSS_LOADER_EXPORT___;
