import React from "react";

import map from "lodash/map";
import classNames from "classnames";

import Button from "UIComponents/Button";
import UIModal from "UIComponents/UIModal";
import Loading from "UIComponents/Loading";
import CloseIcon from "SvgComponents/CloseIcon";
import DateSelector from "UIComponents/DateSelector";
import InputTextArea from "UIComponents/InputTextArea";
import InputTextField from "UIComponents/InputTextField";
import LookUpSearchbar from "UIComponents/LookUpSearchbar";
import FilterLabelButton from "UIComponents/FilterLabelButton";
import classes from "./DialogBox.scss";

const ModalBody = props => {
  const { formInputProps, warningTextBody, bodyContainerStyle, warningTextBodyStyle } =
    props;
  return (
    <div className={classes.modalBodyContainer} style={bodyContainerStyle}>
      {!!warningTextBody && (
        <div className={classes.warningTextBody} style={warningTextBodyStyle}>
          {warningTextBody}
        </div>
      )}
      {map(formInputProps, ({ id, formType, ...restProps }) =>
        formType === "dropdown" ? (
          <FilterLabelButton key={id} {...restProps} />
        ) : formType === "lookup" ? (
          <LookUpSearchbar key={id} {...restProps} />
        ) : formType === "date" ? (
          <DateSelector key={id} {...restProps} />
        ) : formType === "textArea" ? (
          <InputTextArea key={id} {...restProps} />
        ) : (
          <InputTextField key={id} {...restProps} />
        )
      )}
    </div>
  );
};

const DialogBox = props => {
  const {
    headerContainerStyle,
    onCloseModal = null,
    title,
    formInputProps,
    warningTextBody,
    warningTextBodyStyle,
    bodyContainerStyle,
    footerContainerStyle,
    secondaryButton,
    primaryButton,
    primaryButtonProps,
    secondaryButtonProps,
    onClickPrimaryButton,
    onClickSecondaryButton,
    showModal,
    setShowModal,
    isLoading = false,
    modalContainerClass,
  } = props;

  const handleOnClosingModal = () => {
    setShowModal(null);
    onCloseModal && onCloseModal();
  };

  const handleOnClickSecondaryButton = () => {
    onClickSecondaryButton();
    handleOnClosingModal();
  };

  const handleOnClickPrimaryButton = () => {
    onClickPrimaryButton();
    handleOnClosingModal();
  };

  const modalClass = classNames(
    { [classes.modalContainer]: true },
    { [modalContainerClass]: !!modalContainerClass }
  );

  return (
    <UIModal
      isOpen={!!showModal}
      modalContent={modalClass}
      onRequestClose={handleOnClosingModal}
    >
      <div className={classes.headerContainer} style={headerContainerStyle}>
        <div className={classes.title}>{title}</div>
        <Button
          variant={"plain"}
          isIconButton={true}
          onClick={handleOnClosingModal}
          className={classes.closeIconButton}
        >
          <CloseIcon />
        </Button>
      </div>

      <form className={classes.formContainer}>
        {isLoading ? (
          <Loading />
        ) : (
          <ModalBody
            formInputProps={formInputProps}
            warningTextBody={warningTextBody}
            bodyContainerStyle={bodyContainerStyle}
            warningTextBodyStyle={warningTextBodyStyle}
          />
        )}

        <div className={classes.footerContainer} style={footerContainerStyle}>
          {!!secondaryButton && (
            <Button onClick={handleOnClickSecondaryButton} {...secondaryButtonProps}>
              {secondaryButton}
            </Button>
          )}
          {!!primaryButton && (
            <Button
              type={"submit"}
              onClick={handleOnClickPrimaryButton}
              {...primaryButtonProps}
            >
              {primaryButton}
            </Button>
          )}
        </div>
      </form>
    </UIModal>
  );
};

export default DialogBox;

//Why does this code exist?
//This enables the end user to be presented with information and accept input. For example, it is used to enable the user to add members to a project team.
