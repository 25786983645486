// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuestionsAsked_questionsAskedContainer_riDo9{justify-content:flex-start;height:75.2vh}.QuestionsAsked_header_TNLFg{flex:1;display:flex;padding:30px 40px;align-items:center;justify-content:flex-end;min-height:92px;max-height:92px;width:100%;border-bottom:2px solid #c9c9c9}.QuestionsAsked_questionsAnswersContainer_R0jvh{flex:1;display:flex;flex-direction:column;align-items:center;gap:20px;padding:20px 40px 20px 40px;overflow:auto;width:100%;height:max-content;margin-right:12px}.QuestionsAsked_questionsAnswersContainer_R0jvh::-webkit-scrollbar-track{margin-top:132px !important;margin-bottom:132px !important}", "",{"version":3,"sources":["webpack://./src/routes/routes/AppLayout/routes/Projects/routes/Meeting/routes/QuestionsAsked/components/QuestionsAsked.scss"],"names":[],"mappings":"AAEA,8CACE,0BAAA,CACA,aAAA,CAGF,6BACE,MAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CACA,wBAAA,CACA,eAAA,CACA,eAAA,CACA,UAAA,CACA,+BAAA,CAGF,gDACE,MAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CACA,2BAAA,CACA,aAAA,CACA,UAAA,CACA,kBAAA,CACA,iBAAA,CAEA,yEACE,2BAAA,CACA,8BAAA","sourcesContent":["@import \"styles/Colors.scss\";\r\n\r\n.questionsAskedContainer {\r\n  justify-content: flex-start;\r\n  height: 75.2vh;\r\n}\r\n\r\n.header {\r\n  flex: 1;\r\n  display: flex;\r\n  padding: 30px 40px;\r\n  align-items: center;\r\n  justify-content: flex-end;\r\n  min-height: 92px;\r\n  max-height: 92px;\r\n  width: 100%;\r\n  border-bottom: 2px solid $borderSecondary;\r\n}\r\n\r\n.questionsAnswersContainer {\r\n  flex: 1;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  gap: 20px;\r\n  padding: 20px 40px 20px 40px;\r\n  overflow: auto;\r\n  width: 100%;\r\n  height: max-content;\r\n  margin-right: 12px;\r\n\r\n  &::-webkit-scrollbar-track {\r\n    margin-top: 132px !important;\r\n    margin-bottom: 132px !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questionsAskedContainer": "QuestionsAsked_questionsAskedContainer_riDo9",
	"header": "QuestionsAsked_header_TNLFg",
	"questionsAnswersContainer": "QuestionsAsked_questionsAnswersContainer_R0jvh"
};
export default ___CSS_LOADER_EXPORT___;
