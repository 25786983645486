import React from "react";

const SearchIcon = React.memo(props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill="#706E6B"
        d="M15.263 13.938 11.14 9.846a5.862 5.862 0 0 0 1.046-4.216c-.37-2.646-2.523-4.738-5.2-4.984C3.355.276.278 3.353.647 7.015c.246 2.646 2.339 4.83 4.985 5.2a5.861 5.861 0 0 0 4.215-1.046l4.093 4.092a.446.446 0 0 0 .646 0l.646-.646a.516.516 0 0 0 .03-.677ZM2.463 6.43a3.979 3.979 0 0 1 3.969-3.969 3.979 3.979 0 0 1 3.97 3.97 3.979 3.979 0 0 1-3.97 3.968 3.96 3.96 0 0 1-3.97-3.969Z"
      />
    </svg>
  );
});

SearchIcon.displayName = "SearchIcon";
export default SearchIcon;
