// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Box_container_wKQzV{flex:1;display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%;height:100%;background:#fafafa;box-shadow:0px 4px 4px rgba(0,0,0,.07);border-radius:16px}.Box_rowAligned_y8LkK{flex-direction:row}.Box_activeContainer_AzsRy{border:3px solid #23abc8}", "",{"version":3,"sources":["webpack://./src/UIComponents/Box/Box.scss","webpack://./src/styles/Colors.scss"],"names":[],"mappings":"AAEA,qBACE,MAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,kBCKS,CDJT,sCAAA,CACA,kBAAA,CAGF,sBACE,kBAAA,CAGF,2BACE,wBAAA","sourcesContent":["@import \"styles/Colors.scss\";\r\n\r\n.container {\r\n  flex: 1;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  width: 100%;\r\n  height: 100%;\r\n  background: $bgActive;\r\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);\r\n  border-radius: 16px;\r\n}\r\n\r\n.rowAligned {\r\n  flex-direction: row;\r\n}\r\n\r\n.activeContainer {\r\n  border: 3px solid $borderPrimary;\r\n}\r\n","// Add all the color constants here\r\n\r\n// PRIMARY COLOR\r\n$primary: #23abc8;\r\n\r\n// BACKGROUND COLORS\r\n$bgDefault: #ffffff;\r\n$bgWhite: #ffffff;\r\n$bgPrimary: #23abc8;\r\n$bgLightSubtle: #f5f5f5;\r\n$bgSubtle: #ebebeb;\r\n$bgDarkSubtle: #666666;\r\n$bgHover: #9d9d9d;\r\n$bgDarkHover: #d9d9d9;\r\n$bgDisabled: #e0e0e0;\r\n$bgActive: #fafafa;\r\n$bgDanger: #e14942;\r\n$bgSuccess: #3eaf3f;\r\n$bgTextField: #cfcfcf;\r\n$bgSubtleButton: #e8e8e8;\r\n$bgModal: #f7f7f7;\r\n\r\n// BORDER COLORS\r\n$borderLightSubtle: #eaeaea;\r\n$borderSubtle: #cfcfcf;\r\n$borderDarkSubtle: #666666;\r\n$borderSecondary: #c9c9c9;\r\n$borderPrimary: #23abc8;\r\n\r\n// FONT COLORS\r\n$textDefault: #000000;\r\n$textWhite: #ffffff;\r\n$textPrimary: #23abc8;\r\n$textDisabled: #9a9a9a;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Box_container_wKQzV",
	"rowAligned": "Box_rowAligned_y8LkK",
	"activeContainer": "Box_activeContainer_AzsRy"
};
export default ___CSS_LOADER_EXPORT___;
