import React, { useEffect, useState } from "react";

import includes from "lodash/includes";
import { getRouteSegments } from "utils/RoutingHelpers";
import { useNavigate, useLocation } from "react-router-dom";

import HomeIcon from "SvgComponents/HomeIcon";
import RocketIcon from "SvgComponents/RocketIcon";
import ToggleButton from "UIComponents/ToggleButton";
import classes from "./HomeSwitcher.scss";

const homeSwitcherButtonsConfig = [
  {
    id: "HOME",
    value: "home",
    Component: props => <HomeIcon {...props} />,
  },
  {
    id: "PROJECTS",
    value: "projects",
    Component: props => <RocketIcon {...props} />,
  },
];

const getInitialTabActiveValue = ({ routeSegments }) => {
  let activeTabValue = "home";
  if (includes(routeSegments, "projects")) activeTabValue = "projects";
  return activeTabValue;
};

const HomeSwitcher = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const routeSegments = getRouteSegments({ location });
  const [activeTabValue, setActiveTabValue] = useState(
    getInitialTabActiveValue({ routeSegments })
  );

  useEffect(() => {
    if (includes(routeSegments, "projects") || includes(routeSegments, "home"))
      setActiveTabValue(getInitialTabActiveValue({ routeSegments }));
  }, [routeSegments]);

  const onToggleSwitch = event => {
    const { activeValue } = event;
    navigate(activeValue);
  };

  return (
    <div className={classes.homeSwitcherContainer}>
      <ToggleButton
        onSwitch={onToggleSwitch}
        initialActiveValue={activeTabValue}
        buttonsConfig={homeSwitcherButtonsConfig}
      />
    </div>
  );
};

export default HomeSwitcher;

//Why does this code exist?
//This enables the end user to toggle the home or projects button to navigate to different sections of the app.
