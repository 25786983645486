import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { getOrgId } from "modules/commonSelectors";
import { getIsTokenValid } from "services/LocalStorage";

const ProtectedRoutes = props => {
  const { component: Component, source = null } = props;
  const orgId = useSelector(getOrgId);
  const isUserLoggedIn = getIsTokenValid() && !!orgId;
  const isSourceLoginPage = source === "login";

  return isUserLoggedIn ? (
    isSourceLoginPage ? (
      <Navigate to="/home" replace />
    ) : (
      <Component />
    )
  ) : isSourceLoginPage ? (
    <Component />
  ) : (
    <Navigate to="/" replace />
  );
};

export default ProtectedRoutes;
