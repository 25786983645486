import axiosInstance from "./axiosInstance";

const populateParams = ({ url, params }) => {
  if (url.includes(":") && params) {
    for (const [key, value] of Object.entries(params)) {
      if (url.includes(":" + key)) url = url.replace(":" + key, value);
    }
  }

  return url;
};

const populateQuery = ({ url, query }) => {
  let queryString = "?";

  if (query) {
    const entries = Object.entries(query);
    entries.forEach((entry, index) => {
      const [key, value] = entry;
      queryString += `${key}=${value}`;
      if (index !== entries.length - 1) queryString += `&`;
    });
  }

  if (queryString !== "?") return url + queryString;
  return url;
};

const getUpdatedUrl = ({ url, payload }) => {
  const { params, query } = payload;
  url = populateParams({ url, params });
  url = populateQuery({ url, query });
  return url;
};

const DEFAULT_PAYLOAD = { params: {}, query: {} };

class ApiClient {
  constructor(api) {
    this.api = api;
  }

  async get({ url, payload = DEFAULT_PAYLOAD }) {
    url = getUpdatedUrl({ url, payload });
    return this.api.get(url);
  }

  async post({ url, data, payload = DEFAULT_PAYLOAD, config = {} }) {
    url = getUpdatedUrl({ url, payload });
    return this.api.post(url, data, config);
  }

  async put({ url, data, payload = DEFAULT_PAYLOAD }) {
    url = getUpdatedUrl({ url, payload });
    return this.api.put(url, data);
  }

  async patch({ url, data, payload = DEFAULT_PAYLOAD }) {
    url = getUpdatedUrl({ url, payload });
    return this.api.patch(url, data);
  }

  async delete({ url, payload = DEFAULT_PAYLOAD, config = {} }) {
    url = getUpdatedUrl({ url, payload });
    return this.api.delete(url, config);
  }
}

const apiClient = new ApiClient(axiosInstance);
export default apiClient;
