import React from "react";

import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";

import classNames from "classnames";

import Loading from "UIComponents/Loading";
import FeedListItemV2 from "AppComponents/FeedListV2/FeedListItemV2";
import classes from "./FeedListV2.scss";

const getHeaderElements = ({
  columnList,
  parentStyle,
  headerStyle,
  headerCellCustomStyle,
  getCustomHeaderComponent,
}) => {
  const totalSubtextCount = get(
    filter(columnList, item => !!item.subText),
    "length",
    0
  );
  return (
    <div className={classes.listHeader} style={{ ...parentStyle, ...headerStyle }}>
      {map(columnList, column => {
        const { id, type, label, subText, style, subTextStyles } = column;
        if (type === "CUSTOM" && getCustomHeaderComponent)
          return getCustomHeaderComponent({ column });

        const headerCellClasses = classNames(
          { [classes.headerItem]: true },
          { [classes.intCell]: type === "INT_COLUMN" },
          { [classes.actionCell]: type === "ACTIONS" }
        );

        return (
          <div
            key={id}
            className={headerCellClasses}
            style={{
              ...(style || {}),
              ...(headerCellCustomStyle || {}),
            }}
          >
            <div className={classes.headerTitleContainer}>{label}</div>

            {totalSubtextCount > 0 && (
              <div className={classes.subText} style={subTextStyles}>
                {subText}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const FeedListV2 = React.memo(props => {
  const {
    isData,
    feedData = [],
    isLoading,
    columnList = [],
    onItemClick,
    isItemClickEnable,
    getCustomComponent,
    getCustomHeaderComponent,

    emptyComponent,
    showHeaderRow = true,
    useCustomFuncForDefault = false,

    headerStyle,
    parentStyle,
    containerStyle = {},
    rowWrapperClass,
    enableStickyHeader,
    headerCellCustomStyle,
  } = props;

  const headerClass = classNames({
    [classes.headerContainer]: enableStickyHeader,
  });

  const showLoader = !isData && isLoading;
  const showEmptyState = feedData.length === 0 && isData && !isLoading;

  const getRowComponent = ({ feedData }) =>
    map(feedData, obj => (
      <FeedListItemV2
        key={obj.id}
        listItem={obj}
        columns={columnList}
        parentStyle={parentStyle}
        rowWrapperClass={rowWrapperClass}
        isItemClickEnable={isItemClickEnable}
        getCustomComponent={getCustomComponent}
        useCustomFuncForDefault={useCustomFuncForDefault}
        onItemClick={() => onItemClick && onItemClick(obj.id)}
      />
    ));

  const tableHeader =
    showHeaderRow &&
    getHeaderElements({
      columnList,
      parentStyle,
      headerStyle,
      headerCellCustomStyle,
      getCustomHeaderComponent,
    });
  const rows = getRowComponent({ feedData });

  return (
    <div className={classes.container} style={containerStyle}>
      <div className={classes.containerList}>
        <div className={headerClass}>{tableHeader}</div>
        <div className={classes.feedListContainer}>
          {rows}
          {showLoader && <Loading />}
          {showEmptyState && (emptyComponent ? emptyComponent : <>{"No Data"}</>)}
        </div>
      </div>
    </div>
  );
});

FeedListV2.displayName = "FeedListV2";
export default FeedListV2;

//Why does this code exist?
//This enables the end user to be nealty shown tabular data with consistent styling.
