// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UILabel_container_Z7EF4{display:flex;flex-shrink:0;width:100%}.UILabel_inputLabel_dzdkG{display:flex;font-size:.75rem}", "",{"version":3,"sources":["webpack://./src/UIComponents/UILabel/UILabel.scss","webpack://./src/styles/Typography.scss"],"names":[],"mappings":"AAEA,yBACE,YAAA,CACA,aAAA,CACA,UAAA,CAGF,0BACE,YAAA,CCNA,gBAAA","sourcesContent":["@import \"styles/Typography.scss\";\r\n\r\n.container {\r\n  display: flex;\r\n  flex-shrink: 0;\r\n  width: 100%;\r\n}\r\n\r\n.inputLabel {\r\n  display: flex;\r\n  @include fontSize(1.2rem);\r\n}\r\n\r\n//Why does this code exist?\r\n//This applies styling to the labels.\r\n","$fontScalingFactor: 0.625;\r\n\r\n@mixin fontSize($baseSize) {\r\n  font-size: $baseSize * $fontScalingFactor;\r\n}\r\n\r\n@mixin regularFont {\r\n  font-weight: 400;\r\n}\r\n\r\n@mixin mediumFont {\r\n  font-weight: 500;\r\n}\r\n\r\n@mixin demiBoldFont {\r\n  font-weight: 600;\r\n}\r\n\r\n@mixin boldFont {\r\n  font-weight: 700;\r\n}\r\n\r\n@mixin abel {\r\n  font-family: Abel;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UILabel_container_Z7EF4",
	"inputLabel": "UILabel_inputLabel_dzdkG"
};
export default ___CSS_LOADER_EXPORT___;
