import React from "react";
import PageNotFound from "AppComponents/PageNotFound";

const PageNotFoundRoutes = () => ({
  path: "*",
  element: <PageNotFound />,
  children: [],
});

export default PageNotFoundRoutes;
