import React from "react";

const QuestionAnswerLink = React.memo(props => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={48} height={27} fill="none" {...props}>
      <path stroke={stroke} strokeWidth={2} d="M1 0v11c0 8.284 6.716 15 15 15h32" />
    </svg>
  );
});

QuestionAnswerLink.displayName = "QuestionAnswerLink";
export default QuestionAnswerLink;
