import { useQuery } from "@tanstack/react-query";

import apiClient from "services/api/apiClient";
import apiEndpoints, { dataCacheKeys } from "Constants/ApiEndpoints";

const getOrganizations = async () => {
  const response = await apiClient.get({ url: apiEndpoints.GET_ORGANIZATIONS });
  return response.organisations;
};

export const useGetOrganizations = () => {
  return useQuery({
    queryKey: dataCacheKeys.organizations,
    queryFn: getOrganizations,
  });
};

const getOrgMembers = async ({ orgId }) => {
  const payload = { params: { orgId } };
  const response = await apiClient.get({
    payload,
    url: apiEndpoints.GET_ORG_MEMBERS,
  });
  return response.Members;
};

export const useGetOrgMembers = ({ orgId }) => {
  return useQuery({
    queryKey: dataCacheKeys.orgMembers({ orgId }),
    queryFn: () => getOrgMembers({ orgId }),
  });
};

const getOrgDetails = async ({ orgId }) => {
  const payload = { params: { organisationId: orgId } };
  const response = await apiClient.get({
    payload,
    url: apiEndpoints.GET_ORGANIZATION_DETAILS,
  });
  return response.organisation;
};

export const useGetOrgDetails = ({ orgId, params = {} }) => {
  return useQuery({
    queryKey: dataCacheKeys.organization({ orgId }),
    queryFn: () => getOrgDetails({ orgId }),
    ...params,
  });
};

// Stripe Payment Link
const getPaymentLink = async ({ orgId }) => {
  const payload = { params: { id: orgId } };
  const response = await apiClient.get({ payload, url: apiEndpoints.GET_PAYMENT_LINK });
  return response.paymentLink;
};

export const useGetPaymentLink = ({ orgId, params = {} }) => {
  return useQuery({
    queryKey: dataCacheKeys.paymentLink({ orgId }),
    queryFn: () => getPaymentLink({ orgId }),
    ...params,
  });
};
