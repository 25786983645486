// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loading_container_LHIQn{flex:1;display:flex;align-items:center;justify-content:center;background-color:rgba(0,0,0,0);height:100%;width:100%}.Loading_containerBottomCenter_g7pD_{justify-content:center;align-items:flex-end}", "",{"version":3,"sources":["webpack://./src/UIComponents/Loading/Loading.scss"],"names":[],"mappings":"AAAA,yBACE,MAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,8BAAA,CACA,WAAA,CACA,UAAA,CAGF,qCAEE,sBAAA,CACA,oBAAA","sourcesContent":[".container {\r\n  flex: 1;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  background-color: transparent;\r\n  height: 100%;\r\n  width: 100%;\r\n}\r\n\r\n.containerBottomCenter {\r\n  composes: container;\r\n  justify-content: center;\r\n  align-items: flex-end;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Loading_container_LHIQn",
	"containerBottomCenter": "Loading_containerBottomCenter_g7pD_ Loading_container_LHIQn"
};
export default ___CSS_LOADER_EXPORT___;
