import React from "react";

const DeleteIcon = React.memo(props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill="#706E6B"
        d="M17.886 3.846h-4.808V2.308c0-.846-.692-1.539-1.539-1.539H9.232c-.846 0-1.539.693-1.539 1.539v1.538H2.886a.592.592 0 0 0-.577.577v1.154c0 .308.269.577.577.577h15c.307 0 .576-.27.576-.577V4.423a.591.591 0 0 0-.576-.577ZM9.232 2.692c0-.23.154-.384.384-.384h1.539c.23 0 .384.154.384.384v1.154H9.232V2.692Zm7.115 5H4.424a.592.592 0 0 0-.577.577v9.039c0 1.077.846 1.923 1.923 1.923H15a1.904 1.904 0 0 0 1.924-1.923V8.269a.592.592 0 0 0-.577-.577Zm-7.115 8.462c0 .23-.154.384-.385.384h-.77c-.23 0-.384-.153-.384-.384v-5.385c0-.23.154-.384.385-.384h.769c.23 0 .385.154.385.384v5.385Zm3.846 0c0 .23-.154.384-.385.384h-.769c-.23 0-.385-.153-.385-.384v-5.385c0-.23.154-.384.385-.384h.77c.23 0 .384.154.384.384v5.385Z"
      />
    </svg>
  );
});

DeleteIcon.displayName = "DeleteIcon";
export default DeleteIcon;
