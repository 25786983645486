import React from "react";

import Modal from "react-modal";
import classNames from "classnames";

import Loading from "UIComponents/Loading";
import classes from "./FullScreenLoader.scss";

const FullScreenLoader = React.memo(props => {
  const { overlayContainer } = props;
  const overlayContainerClass = classNames(
    { [classes.overlayContainer]: true },
    { [overlayContainer]: !!overlayContainer }
  );

  return (
    <Modal
      isOpen={true}
      ariaHideApp={false}
      overlayClassName={overlayContainerClass}
      className={classes.fullScreenModalContent}
    >
      <Loading />
    </Modal>
  );
});

FullScreenLoader.displayName = "FullScreenLoader";
export default FullScreenLoader;
