import React from "react";
import ProjectTeam from "./components";

const ProjectTeamRoutes = () => {
  return {
    path: "projectTeam",
    element: <ProjectTeam />,
    children: [],
  };
};

export default ProjectTeamRoutes;
