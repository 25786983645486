import React from "react";

import withAsyncRouteLoading from "Hocs/withAsyncRouteLoading";

const Organizations = withAsyncRouteLoading(() =>
  import(/* webpackChunkName: "organizations" */ "./components")
);

const OrganizationsRoutes = () => {
  return {
    path: "organizations",
    element: <Organizations documentTitle={"Settings"} />,
    children: [],
  };
};

export default OrganizationsRoutes;

//Why does this code exist?
//This create the route for the user to access their plans.
