import React from "react";

import get from "lodash/get";
import find from "lodash/find";
import first from "lodash/first";
import classNames from "classnames";

import Dropdown from "UIComponents/Dropdown";
import { fontColors } from "Constants/ColorConstants";
import CaretDownIcon from "SvgComponents/CaretDownIcon";
import classes from "./FilterLabelButton.scss";

const dropdownOverContainerStyle = {
  left: "0",
  top: "36px",
  borderRadius: "4px",
  border: "1px solid #DDDBDA",
};

const dropdownStyle = { width: "100%" };

const FilterLabelButton = props => {
  const {
    name,
    options,
    isDisabled,
    triggerStyle = {},
    isFullWidth = true,
    onClickOptions,
    dropdownOverlay,
    selectedOptionKey,
    overContainerStyle,
    customDropdownStyle,
  } = props;
  const selectedOption = find(options, ({ key }) => key == selectedOptionKey);
  const selectedOptionLabel =
    get(selectedOption, "label", null) || get(first(options), "label", "");

  const containerClasses = classNames(
    { [classes.container]: true },
    { [classes.disabledFilterButton]: isDisabled }
  );

  return (
    <Dropdown
      name={name}
      options={options}
      isDisabled={isDisabled}
      overlay={dropdownOverlay}
      onClickOptions={onClickOptions}
      isFullWidth={isFullWidth}
      triggerStyle={triggerStyle}
      customDropdownStyle={{ ...dropdownStyle, ...customDropdownStyle }}
      overContainerStyle={{ ...dropdownOverContainerStyle, ...overContainerStyle }}
      selectedOptionKey={selectedOptionKey || get(first(options), "key", "")}
    >
      <div className={containerClasses}>
        <div className={classes.leftContainer}>{selectedOptionLabel}</div>
        <div className={classes.rightContainer}>
          <CaretDownIcon fill={isDisabled ? fontColors.textDisabled : null} />
        </div>
      </div>
    </Dropdown>
  );
};

export default FilterLabelButton;

//Why does this code exist?
//This code is needed to enable the user to make selction in the web app from a dropdown that can have thier selections filtered.
