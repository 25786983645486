import { localStorageKeys } from "Constants/LocalStorageConstants";

const { USER_INFO, ORG_INFO, IS_TOKEN_VALID } = localStorageKeys;

// GENERICS
export const getJsonItem = key => {
  try {
    const item = localStorage.getItem(key);
    if (!item) return;
    return JSON.parse(item);
  } catch (error) {
    console.error(error);
    return;
  }
};

export const setJsonItem = ({ key, value }) => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error(error);
  }
};

export const clearLocalStorageForKey = ({ key }) => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

// USER_INFO
export const getUserInfoFromStorage = () => {
  return getJsonItem(USER_INFO);
};

// ORG_ID
export const getCurrentOrgIdFromStorage = () => {
  return JSON.parse(getJsonItem(ORG_INFO).id);
};

//IS_TOKEN_VALID
export const getIsTokenValid = () => {
  return getJsonItem(IS_TOKEN_VALID);
};

export const setIsTokenValid = ({ value }) => {
  return setJsonItem({ key: IS_TOKEN_VALID, value });
};
