import last from "lodash/last";
import isEmpty from "lodash/isEmpty";

// STRING HELPERS
export const getNameInitials = ({ name = "" }) => {
  const names = name.split(" ");
  if (isEmpty(names)) return "";

  const firstNameInitial = names[0][0];
  const lastNameInitial = last(names)[0];
  return `${firstNameInitial}${lastNameInitial}`.toUpperCase();
};

export const capitalizeFirstLetter = ({ string }) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// REGEX HELPERS
export const escapeRegExp = ({ string }) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const areEqual = ({ str1, str2 }) => {
  const regex = new RegExp("^" + escapeRegExp({ string: str1 }) + "$", "i");
  return regex.test(str2);
};

export const isSubstring = ({ substring, masterString }) => {
  const regex = new RegExp(escapeRegExp({ string: substring }), "i");
  return regex.test(masterString);
};

//Why does this code exist?
//These functions exisits to enable easy handling of strings and routes in the application.
