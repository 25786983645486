import React from "react";

const CheckIcon = React.memo(props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill="#23ABC8"
        d="M5.833 13.37.756 8.261a.483.483 0 0 1 0-.677l.677-.677a.483.483 0 0 1 .677 0l3.815 3.846a.335.335 0 0 0 .461 0l7.477-7.539a.483.483 0 0 1 .677 0l.677.677a.483.483 0 0 1 0 .677l-8.708 8.8a.435.435 0 0 1-.676 0Z"
      />
    </svg>
  );
});

CheckIcon.displayName = "CheckIcon";
export default CheckIcon;
