import React from "react";

import Button from "UIComponents/Button/Button";
import classes from "./EmptyStateComponent.scss";

const EmptyStateComponent = React.memo(props => {
  const { title, subtitle = null, primaryButtonProps, primaryButtonText = null } = props;

  return (
    <div className={classes.container}>
      <span className={classes.title}>{title}</span>
      {subtitle && <span className={classes.subtitle}>{subtitle}</span>}
      {primaryButtonText && (
        <Button size={"lg"} {...primaryButtonProps}>
          {primaryButtonText}
        </Button>
      )}
    </div>
  );
});

EmptyStateComponent.displayName = "EmptyStateComponent";
export default EmptyStateComponent;
