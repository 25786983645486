import React from "react";

import withAsyncRouteLoading from "Hocs/withAsyncRouteLoading";

const Users = withAsyncRouteLoading(() =>
  import(/* webpackChunkName: "users" */ "./components")
);

const UsersRoutes = () => {
  return {
    path: "users",
    element: <Users documentTitle={"Settings"} />,
    children: [],
  };
};

export default UsersRoutes;

//Why does this code exist?
//This create the route for the user to access their plans.
