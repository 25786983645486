// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Transcript_transcriptBoxContainer_e4R11{justify-content:flex-start;height:75.2vh}.Transcript_header_ckPYc{flex:1;display:flex;padding:30px 40px;align-items:center;justify-content:flex-end;min-height:92px;max-height:92px;width:100%;border-bottom:2px solid #c9c9c9}.Transcript_bodyContainer_wL6YQ{flex:1;display:flex;flex-direction:column;align-items:center;justify-content:center;padding:32px 40px;width:100%}", "",{"version":3,"sources":["webpack://./src/routes/routes/AppLayout/routes/Projects/routes/Meeting/routes/Transcripts/components/Transcript.scss"],"names":[],"mappings":"AAEA,yCACE,0BAAA,CACA,aAAA,CAGF,yBACE,MAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CACA,wBAAA,CACA,eAAA,CACA,eAAA,CACA,UAAA,CACA,+BAAA,CAGF,gCACE,MAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,UAAA","sourcesContent":["@import \"styles/Colors.scss\";\r\n\r\n.transcriptBoxContainer {\r\n  justify-content: flex-start;\r\n  height: 75.2vh;\r\n}\r\n\r\n.header {\r\n  flex: 1;\r\n  display: flex;\r\n  padding: 30px 40px;\r\n  align-items: center;\r\n  justify-content: flex-end;\r\n  min-height: 92px;\r\n  max-height: 92px;\r\n  width: 100%;\r\n  border-bottom: 2px solid $borderSecondary;\r\n}\r\n\r\n.bodyContainer {\r\n  flex: 1;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  padding: 32px 40px;\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transcriptBoxContainer": "Transcript_transcriptBoxContainer_e4R11",
	"header": "Transcript_header_ckPYc",
	"bodyContainer": "Transcript_bodyContainer_wL6YQ"
};
export default ___CSS_LOADER_EXPORT___;
