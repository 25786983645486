import last from "lodash/last";
import split from "lodash/split";

export const getRouteSegments = ({ location }) => {
  return split(location.pathname, "/");
};

export const getCurrentRoute = ({ location }) => {
  const routeSegment = getRouteSegments({ location });
  return last(routeSegment);
};

export const redirectSettingsRoutes = ({ navigate, canManageBilling }) => {
  if (canManageBilling) return;
  else navigate("../users", { replace: true });
};

export const redirectProjectRoutes = ({ navigate, isProjectContextFilled }) => {
  if (!isProjectContextFilled) navigate("../projectContext", { replace: true });
  else return;
};

export const redirectMeetingRoutes = ({
  navigate,
  isMeetingCompleted,
  filledMeetingPurpose,
}) => {
  if (!filledMeetingPurpose) navigate("../preMeetingContext", { replace: true });
  else if (!isMeetingCompleted) navigate("../preparedQuestions", { replace: true });
  else return;
};
