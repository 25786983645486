import React from "react";

import classNames from "classnames";
import classes from "./Box.scss";

const Box = React.memo(props => {
  const {
    children,
    className,
    containerStyle,
    isActive = false,
    isRowAligned = false,
  } = props;

  const containerClass = classNames({
    [className]: !!className,
    [classes.container]: true,
    [classes.rowAligned]: isRowAligned,
    [classes.activeContainer]: isActive,
  });

  return (
    <div className={containerClass} style={containerStyle}>
      {children}
    </div>
  );
});

Box.displayName = "Box";
export default Box;

//Why does this code exist?
//This code acts as a reusable wrapper for components. Without this component all parts of the application contained within this component would fail to function properly.
