// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectTeamModal_modalContainer_dzbRS{display:flex;border-radius:36px;gap:20px;background-color:#f7f7f7;box-shadow:0px 4px 4px rgba(0,0,0,.07);padding:24px 44px 20px 44px;height:280px;width:416px}", "",{"version":3,"sources":["webpack://./src/routes/routes/AppLayout/routes/Projects/routes/ProjectTeam/components/ProjectTeamModal/ProjectTeamModal.scss","webpack://./src/styles/Colors.scss"],"names":[],"mappings":"AAEA,uCACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,wBCcQ,CDbR,sCAAA,CACA,2BAAA,CACA,YAAA,CACA,WAAA","sourcesContent":["@import \"styles/Colors.scss\";\r\n\r\n.modalContainer {\r\n  display: flex;\r\n  border-radius: 36px;\r\n  gap: 20px;\r\n  background-color: $bgModal;\r\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);\r\n  padding: 24px 44px 20px 44px;\r\n  height: 280px;\r\n  width: 416px;\r\n}\r\n","// Add all the color constants here\r\n\r\n// PRIMARY COLOR\r\n$primary: #23abc8;\r\n\r\n// BACKGROUND COLORS\r\n$bgDefault: #ffffff;\r\n$bgWhite: #ffffff;\r\n$bgPrimary: #23abc8;\r\n$bgLightSubtle: #f5f5f5;\r\n$bgSubtle: #ebebeb;\r\n$bgDarkSubtle: #666666;\r\n$bgHover: #9d9d9d;\r\n$bgDarkHover: #d9d9d9;\r\n$bgDisabled: #e0e0e0;\r\n$bgActive: #fafafa;\r\n$bgDanger: #e14942;\r\n$bgSuccess: #3eaf3f;\r\n$bgTextField: #cfcfcf;\r\n$bgSubtleButton: #e8e8e8;\r\n$bgModal: #f7f7f7;\r\n\r\n// BORDER COLORS\r\n$borderLightSubtle: #eaeaea;\r\n$borderSubtle: #cfcfcf;\r\n$borderDarkSubtle: #666666;\r\n$borderSecondary: #c9c9c9;\r\n$borderPrimary: #23abc8;\r\n\r\n// FONT COLORS\r\n$textDefault: #000000;\r\n$textWhite: #ffffff;\r\n$textPrimary: #23abc8;\r\n$textDisabled: #9a9a9a;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": "ProjectTeamModal_modalContainer_dzbRS"
};
export default ___CSS_LOADER_EXPORT___;
