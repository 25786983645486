import React from "react";

import map from "lodash/map";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import { useSelector } from "react-redux";

import { getUserInfo } from "modules/commonSelectors";
import { ORG_ACCESS_LEVEL_TYPE_OWNER } from "Constants/StringConstants";

import Button from "UIComponents/Button";
import DeleteIcon from "SvgComponents/DeleteIcon";
import FeedListV2 from "AppComponents/FeedListV2";
import FilterLabelButton from "UIComponents/FilterLabelButton";

const feedListContainerStyle = {
  flex: "1",
  width: "100%",
  height: "100%",
  maxHeight: "65vh",
  overflowY: "auto",
};

const MeetingsColumnList = [
  {
    id: "avatar",
    label: "",
    style: { justifyContent: "flex-end", paddingRight: "16px" },
    type: "AVATAR",
  },
  {
    id: "name",
    label: "",
    style: { textTransform: "capitalize" },
    type: "TEXT_COLUMN",
  },
  {
    id: "email",
    label: "",
    style: { justifyContent: "center" },
    type: "TEXT_COLUMN",
  },
  {
    id: "accessType",
    label: "",
    columnDataStyle: { justifyContent: "flex-end", paddingRight: "28px" },
    type: "CUSTOM",
  },
  {
    id: "actions",
    label: "",
    type: "CUSTOM",
    style: { justifyContent: "flex-end" },
  },
];

const UsersFeedList = props => {
  const {
    users,
    isLoading,
    accessLevelOptions = [],
    onChangeMemberAccessLevel,
    onDeleteMember = () => {},
    canDeleteMembers,
    canChangeMemberAccessLevel,
  } = props;

  const rootUser = useSelector(getUserInfo);
  const sortedUsers = sortBy(users, ({ email }) => email !== rootUser.email);
  const transformedUsers = map(sortedUsers, ({ id, accessType, name, email }) => {
    const isCurrentUser = email == rootUser.email;
    return {
      id,
      email,
      avatar: { url: isCurrentUser ? rootUser.image : null, name },
      actions: { accessType },
      isMainRow: isCurrentUser,
      accessType: { accessType },
      name: isCurrentUser ? `${name} (You)` : name,
    };
  });

  const onClickDeleteMemberIcon = ({ memberId }) => {
    onDeleteMember({ memberId });
  };

  const handleChange =
    ({ memberId }) =>
    value => {
      const accessLevel = get(value, "accessLevel", "");
      onChangeMemberAccessLevel({ accessLevel, memberId });
    };

  const getCustomComponent = props => {
    const { id: rowId, column, columnData } = props;
    const { id: columnId } = column;
    switch (columnId) {
      case "accessType": {
        const { accessType } = columnData;
        const isAdmin = accessType === ORG_ACCESS_LEVEL_TYPE_OWNER;
        return (
          canChangeMemberAccessLevel && (
            <FilterLabelButton
              name={"accessLevel"}
              isDisabled={isAdmin}
              dropdownOverlay={"checklist"}
              selectedOptionKey={accessType}
              options={accessLevelOptions}
              onClickOptions={handleChange({ memberId: rowId })}
            />
          )
        );
      }
      case "actions": {
        const { accessType } = columnData;
        const isAdmin = accessType !== ORG_ACCESS_LEVEL_TYPE_OWNER && canDeleteMembers;
        return (
          isAdmin && (
            <Button
              variant={"plain"}
              isIconButton={true}
              onClick={() => onClickDeleteMemberIcon({ memberId: rowId })}
            >
              <DeleteIcon />
            </Button>
          )
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <FeedListV2
      showHeaderRow={false}
      isItemClickEnable={false}
      isLoading={isLoading}
      feedData={transformedUsers}
      columnList={MeetingsColumnList}
      getCustomComponent={getCustomComponent}
      containerStyle={feedListContainerStyle}
      parentStyle={{ gridTemplateColumns: "2fr 9fr 9fr 7fr 2fr" }}
    />
  );
};

export default UsersFeedList;

//Why does this code exist?
//This enables the user to see a list of project members on a project including their access level.
