import React from "react";

const CaretDownIcon = React.memo(props => {
  const { fill = "#706E6B" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.84 4.308h10.334c.292 0 .496.4.262.677l-5.05 6.523a.453.453 0 0 1-.73 0L2.55 4.985c-.205-.277-.03-.677.292-.677Z"
      />
    </svg>
  );
});

CaretDownIcon.displayName = "CaretDownIcon";
export default CaretDownIcon;
