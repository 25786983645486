import React from "react";
import QuestionsAsked from "./components";

const QuestionsAskedRoutes = () => {
  return {
    path: "questionsAsked",
    element: <QuestionsAsked />,
    children: [],
  };
};

export default QuestionsAskedRoutes;

//Why does this code exist?
//Exports route to questions asked her to use her computer questions asked.
