import get from "lodash/get";
import { toast } from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";

import apiClient from "services/api/apiClient";
import apiEndpoints, { dataCacheKeys } from "Constants/ApiEndpoints";
import queryClient from "services/api/queryClient";
import { getCurrentOrgIdFromStorage } from "services/LocalStorage";

// Project
const addProject = async ({ name, customer }) => {
  const orgId = getCurrentOrgIdFromStorage();
  const data = {
    name,
    customer,
    end_date: "2023-07-25",
    start_date: "2023-07-25",
    organisationalId: orgId,
  };
  const response = await apiClient.post({
    data,
    url: apiEndpoints.ADD_PROJECT,
  });
  return response;
};

export const useAddProject = () => {
  const orgId = getCurrentOrgIdFromStorage();
  return useMutation({
    mutationFn: addProject,
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: dataCacheKeys.projects({ orgId }) });
    },
    onSettled: (res, error) => {
      error
        ? toast.error("Something went wrong!")
        : toast.success("Project added successfully!");
    },
  });
};

const updateProject = async ({ project, projectId }) => {
  const payload = { params: { projectId } };
  const response = await apiClient.put({
    payload,
    data: project,
    url: apiEndpoints.UPDATE_PROJECT,
  });
  return response;
};

export const useUpdateProject = () => {
  return useMutation({
    mutationFn: updateProject,
    onSuccess: (response, variables) => {
      const projectId = get(variables, "projectId", "");
      return queryClient.invalidateQueries({
        queryKey: dataCacheKeys.project({ projectId }),
      });
    },
    onSettled: (res, error) => {
      error
        ? toast.error("Something went wrong!")
        : toast.success("Project Context updated successfully!");
    },
  });
};

const deleteProject = async ({ projectId }) => {
  const payload = { params: { projectId } };
  const response = await apiClient.delete({
    payload,
    url: apiEndpoints.DELETE_PROJECT,
  });
  return response;
};

export const useDeleteProject = () => {
  const orgId = getCurrentOrgIdFromStorage();
  return useMutation({
    mutationFn: deleteProject,
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: dataCacheKeys.projects({ orgId }) });
    },
    onSettled: (res, error) => {
      error
        ? toast.error("Something went wrong!")
        : toast.success("Project Deleted successfully!");
    },
  });
};

// Meeting
const addMeeting = async ({
  title,
  purpose,
  endTime,
  projectId,
  startTime,
  meetingLink,
}) => {
  const data = {
    title,
    purpose,
    end_time: endTime,
    start_time: startTime,
    project_id: projectId,
    meeting_link: meetingLink,
  };

  const response = await apiClient.post({
    data,
    url: apiEndpoints.ADD_MEETING,
  });
  return response;
};

export const useAddMeeting = ({ projectId }) => {
  return useMutation({
    mutationFn: addMeeting,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: dataCacheKeys.projectMeetings({ projectId }),
      });
    },
    onSettled: (res, error) => {
      error
        ? toast.error("Something went wrong!")
        : toast.success("Meeting Successfully added!");
    },
  });
};

const deleteMeeting = async ({ meetingId }) => {
  const payload = { params: { id: meetingId } };
  const response = await apiClient.delete({
    payload,
    url: apiEndpoints.DELETE_MEETING,
  });
  return response;
};

export const useDeleteMeeting = ({ projectId }) => {
  return useMutation({
    mutationFn: deleteMeeting,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: dataCacheKeys.projectMeetings({ projectId }),
      });
    },
    onSettled: (res, error) => {
      error
        ? toast.error("Something went wrong!")
        : toast.success("Meeting Successfully Deleted!");
    },
  });
};

// Project Member
const addProjectMember = async ({ projectId, accessType, orgMemberId }) => {
  const orgId = getCurrentOrgIdFromStorage();
  const data = {
    orgId,
    projectId,
    accessType,
    orgMemberId,
  };
  const response = await apiClient.post({
    data,
    url: apiEndpoints.ADD_PROJECT_MEMBER,
  });
  return response;
};

export const useAddProjectMember = ({ projectId }) => {
  return useMutation({
    mutationFn: addProjectMember,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: dataCacheKeys.projectMembers({ projectId }),
      });
    },
    onSettled: (res, error) => {
      error
        ? toast.error("Something went wrong!")
        : toast.success("Project Member added successfully!");
    },
  });
};

const updateProjectMember = async ({ accessType, projectMemberId }) => {
  const payload = { params: { id: projectMemberId } };
  const data = { accessType };
  const response = await apiClient.put({
    data,
    payload,
    url: apiEndpoints.UPDATE_PROJECT_MEMBER,
  });
  return response;
};

export const useUpdateProjectMember = ({ projectId }) => {
  return useMutation({
    mutationFn: updateProjectMember,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: dataCacheKeys.projectMembers({ projectId }),
      });
    },
    onSettled: (res, error) => {
      error
        ? toast.error("Something went wrong!")
        : toast.success("Successfully Updated Project Member!");
    },
  });
};

const deleteProjectMember = async ({ projectMemberId }) => {
  const payload = { params: { id: projectMemberId } };
  const response = await apiClient.delete({
    payload,
    url: apiEndpoints.DELETE_PROJECT_MEMBER,
  });
  return response;
};

export const useDeleteProjectMember = ({ projectId }) => {
  return useMutation({
    mutationFn: deleteProjectMember,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: dataCacheKeys.projectMembers({ projectId }),
      });
    },
    onSettled: (res, error) => {
      error
        ? toast.error("Something went wrong!")
        : toast.success("Successfully removed Project Member!");
    },
  });
};
