import React from "react";

import classNames from "classnames";

import classes from "./Loading.scss";
import Spinner from "./Spinner";

const Loading = React.memo(props => {
  const { position = "center", containerStyle = {} } = props;

  const containerClass = classNames({
    [classes.container]: true,
    [classes.containerBottomCenter]: position === "center-bottom",
  });

  return (
    <div className={containerClass} style={containerStyle}>
      <Spinner />
    </div>
  );
});

Loading.displayName = "Loading";
export default Loading;
