// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppHeader_headerContainer_j_5ip{flex:1;display:flex;justify-content:space-between;align-items:center;padding:2px 48px 2px 100px;background:#f5f5f5;box-shadow:0px 4px 4px rgba(0,0,0,.08);max-height:104px;width:100%}.AppHeader_swiftBaLogo_gr_TI{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/Layouts/Partials/AppHeader/AppHeader.scss","webpack://./src/styles/Colors.scss"],"names":[],"mappings":"AAEA,iCACE,MAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,0BAAA,CACA,kBCCc,CAAA,sCAAA,CDCd,gBAAA,CACA,UAAA,CAGF,6BACE,cAAA","sourcesContent":["@import \"styles/Colors.scss\";\r\n\r\n.headerContainer {\r\n  flex: 1;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  padding: 2px 48px 2px 100px;\r\n  background: $bgLightSubtle;\r\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);\r\n  max-height: 104px;\r\n  width: 100%;\r\n}\r\n\r\n.swiftBaLogo {\r\n  cursor: pointer;\r\n}\r\n","// Add all the color constants here\r\n\r\n// PRIMARY COLOR\r\n$primary: #23abc8;\r\n\r\n// BACKGROUND COLORS\r\n$bgDefault: #ffffff;\r\n$bgWhite: #ffffff;\r\n$bgPrimary: #23abc8;\r\n$bgLightSubtle: #f5f5f5;\r\n$bgSubtle: #ebebeb;\r\n$bgDarkSubtle: #666666;\r\n$bgHover: #9d9d9d;\r\n$bgDarkHover: #d9d9d9;\r\n$bgDisabled: #e0e0e0;\r\n$bgActive: #fafafa;\r\n$bgDanger: #e14942;\r\n$bgSuccess: #3eaf3f;\r\n$bgTextField: #cfcfcf;\r\n$bgSubtleButton: #e8e8e8;\r\n$bgModal: #f7f7f7;\r\n\r\n// BORDER COLORS\r\n$borderLightSubtle: #eaeaea;\r\n$borderSubtle: #cfcfcf;\r\n$borderDarkSubtle: #666666;\r\n$borderSecondary: #c9c9c9;\r\n$borderPrimary: #23abc8;\r\n\r\n// FONT COLORS\r\n$textDefault: #000000;\r\n$textWhite: #ffffff;\r\n$textPrimary: #23abc8;\r\n$textDisabled: #9a9a9a;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": "AppHeader_headerContainer_j_5ip",
	"swiftBaLogo": "AppHeader_swiftBaLogo_gr_TI"
};
export default ___CSS_LOADER_EXPORT___;
