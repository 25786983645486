// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppLayout_appContainer_sGpVb{flex:1;display:flex;flex-direction:column;width:100%;overflow:hidden}.AppLayout_bodyContainer_CEDT0{display:flex;padding:28px 104px 20px 36px;gap:36px;width:100%;height:100%}.AppLayout_outletContainer_bAK5j{flex:1;width:100%}", "",{"version":3,"sources":["webpack://./src/Layouts/AppLayout/AppLayout.scss"],"names":[],"mappings":"AAAA,8BACE,MAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,eAAA,CAGF,+BACE,YAAA,CACA,4BAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CAGF,iCACE,MAAA,CACA,UAAA","sourcesContent":[".appContainer {\r\n  flex: 1;\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 100%;\r\n  overflow: hidden;\r\n}\r\n\r\n.bodyContainer {\r\n  display: flex;\r\n  padding: 28px 104px 20px 36px;\r\n  gap: 36px;\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\n.outletContainer {\r\n  flex: 1;\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appContainer": "AppLayout_appContainer_sGpVb",
	"bodyContainer": "AppLayout_bodyContainer_CEDT0",
	"outletContainer": "AppLayout_outletContainer_bAK5j"
};
export default ___CSS_LOADER_EXPORT___;
