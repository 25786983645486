// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuestionsList_messagesContainer_fQzpc{flex:1;display:flex;flex-direction:column;align-items:center;padding:20px 40px 20px 40px;overflow-y:auto;width:100%;height:100%;margin-right:12px}.QuestionsList_messagesContainer_fQzpc::-webkit-scrollbar-track{margin-top:132px !important;margin-bottom:132px !important}", "",{"version":3,"sources":["webpack://./src/routes/routes/AppLayout/routes/Projects/routes/Meeting/routes/PreparedQuestions/components/QuestionsList/QuestionsList.scss"],"names":[],"mappings":"AAAA,uCACE,MAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,2BAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CAEA,gEACE,2BAAA,CACA,8BAAA","sourcesContent":[".messagesContainer {\r\n  flex: 1;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  padding: 20px 40px 20px 40px;\r\n  overflow-y: auto;\r\n  width: 100%;\r\n  height: 100%;\r\n  margin-right: 12px;\r\n\r\n  &::-webkit-scrollbar-track {\r\n    margin-top: 132px !important;\r\n    margin-bottom: 132px !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messagesContainer": "QuestionsList_messagesContainer_fQzpc"
};
export default ___CSS_LOADER_EXPORT___;
