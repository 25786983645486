import React from "react";
import Transcript from "./components";

const TranscriptRoutes = () => {
  return {
    path: "transcript",
    element: <Transcript />,
    children: [],
  };
};

export default TranscriptRoutes;

//Why does this code exist?
//Exports route to transcript.
