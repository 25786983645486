import React from "react";

import Button from "UIComponents/Button";
import MessageBox from "UIComponents/MessageBox";
import DeleteIcon from "SvgComponents/DeleteIcon";
import classes from "./QuestionAnswerBox.scss";

const questionContainerStyle = {
  maxWidth: "100%",
};

const answerContainerStyle = {
  maxWidth: "89%",
  margin: "12px 0 0 auto",
};

const QuestionAnswerBox = props => {
  const {
    id,
    index,
    answer,
    question,
    isAIGenerated,
    onBlurAnswer,
    onFocusAnswer,
    onChangeAnswer,
    onClickDeleteIcon,
  } = props;

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.questionNumber}>{index + 1}</div>
        <MessageBox
          text={question}
          showLabel={true}
          className={classes.question}
          isAIGenerated={isAIGenerated}
          customContainerStyle={questionContainerStyle}
        />
        <Button
          variant={"plain"}
          isIconButton={true}
          className={classes.action}
          onClick={() => onClickDeleteIcon({ questionId: id })}
        >
          <DeleteIcon />
        </Button>
      </div>

      <div className={classes.row}>
        <MessageBox
          text={answer}
          isEditable={true}
          showConnector={true}
          onBlur={onBlurAnswer}
          onInput={onChangeAnswer}
          className={classes.answer}
          customContainerStyle={answerContainerStyle}
          onFocus={() => onFocusAnswer({ id, question, answer })}
        />
      </div>
    </div>
  );
};

export default QuestionAnswerBox;

//Why does this code exist?
//The user requires an easy way to view Questions asked as well as distinguish between AI questions and human generated questions. This code enables that along with other formating.
