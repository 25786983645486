import React from "react";
import ProjectContext from "./components";

const ProjectContextRoutes = () => {
  return {
    path: "projectContext",
    element: <ProjectContext />,
    children: [],
  };
};

export default ProjectContextRoutes;
