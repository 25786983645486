import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const userPersistConfig = {
  key: "user",
  storage: storage,
};

const initialState = {
  id: "",
  name: "",
  email: "",
  image: "",
};

const reducers = {
  setUser: (state, action) => {
    const { user } = action.payload;
    return { ...state, ...user };
  },
  setUserInitialState: state => {
    return { ...state, ...initialState };
  },
};

const { actions, reducer: userReducer } = createSlice({
  name: "user",
  initialState,
  reducers,
});

export const { setUser, setUserInitialState } = actions;
export default persistReducer(userPersistConfig, userReducer);
