import React from "react";

import HomeRoutes from "./routes/Home";
import ProjectsRoutes from "./routes/Projects";
import SettingsRoutes from "./routes/Settings";

import ProtectedRoutes from "utils/ProtectedRoutes";
import AppLayoutPage from "./components";

const AppLayoutRoutes = () => {
  return {
    path: null,
    element: <ProtectedRoutes component={AppLayoutPage} />,
    children: [HomeRoutes(), ProjectsRoutes(), SettingsRoutes()],
  };
};

export default AppLayoutRoutes;

//Why does this code exist?
//This exports the modules to make them available for import.
