// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Avatar_avatarContainer_osHQL{display:flex;justify-content:center;align-items:center;border-radius:50%;border:none;padding:0;background-color:#fff;width:60px;aspect-ratio:1/1}.Avatar_clickable_xZ5Dj{cursor:pointer}.Avatar_userProfileImage_aUmRM{background-color:rgba(0,0,0,0)}", "",{"version":3,"sources":["webpack://./src/UIComponents/Avatar/Avatar.scss","webpack://./src/styles/Colors.scss"],"names":[],"mappings":"AAEA,8BACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CACA,WAAA,CACA,SAAA,CACA,qBCFQ,CDGR,UAAA,CACA,gBAAA,CAGF,wBACE,cAAA,CAGF,+BACE,8BAAA","sourcesContent":["@import \"styles/Colors.scss\";\r\n\r\n.avatarContainer {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  border-radius: 50%;\r\n  border: none;\r\n  padding: 0;\r\n  background-color: $bgWhite;\r\n  width: 60px;\r\n  aspect-ratio: 1/1;\r\n}\r\n\r\n.clickable {\r\n  cursor: pointer;\r\n}\r\n\r\n.userProfileImage {\r\n  background-color: transparent;\r\n}\r\n","// Add all the color constants here\r\n\r\n// PRIMARY COLOR\r\n$primary: #23abc8;\r\n\r\n// BACKGROUND COLORS\r\n$bgDefault: #ffffff;\r\n$bgWhite: #ffffff;\r\n$bgPrimary: #23abc8;\r\n$bgLightSubtle: #f5f5f5;\r\n$bgSubtle: #ebebeb;\r\n$bgDarkSubtle: #666666;\r\n$bgHover: #9d9d9d;\r\n$bgDarkHover: #d9d9d9;\r\n$bgDisabled: #e0e0e0;\r\n$bgActive: #fafafa;\r\n$bgDanger: #e14942;\r\n$bgSuccess: #3eaf3f;\r\n$bgTextField: #cfcfcf;\r\n$bgSubtleButton: #e8e8e8;\r\n$bgModal: #f7f7f7;\r\n\r\n// BORDER COLORS\r\n$borderLightSubtle: #eaeaea;\r\n$borderSubtle: #cfcfcf;\r\n$borderDarkSubtle: #666666;\r\n$borderSecondary: #c9c9c9;\r\n$borderPrimary: #23abc8;\r\n\r\n// FONT COLORS\r\n$textDefault: #000000;\r\n$textWhite: #ffffff;\r\n$textPrimary: #23abc8;\r\n$textDisabled: #9a9a9a;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarContainer": "Avatar_avatarContainer_osHQL",
	"clickable": "Avatar_clickable_xZ5Dj",
	"userProfileImage": "Avatar_userProfileImage_aUmRM"
};
export default ___CSS_LOADER_EXPORT___;
