import React from "react";

import get from "lodash/get";
import map from "lodash/map";
import classNames from "classnames";

import Avatar from "UIComponents/Avatar";
import classes from "./FeedListItemV2.scss";

const FeedListItemV2 = props => {
  const {
    columns,
    listItem,
    parentStyle,
    onItemClick,
    rowWrapperClass,
    isItemClickEnable,
    getCustomComponent,
    useCustomFuncForDefault = false,
  } = props;

  const getColumnData = ({ column, columnData, id }) => {
    const { type, columnDataStyle } = column;
    //  disabled,

    switch (type) {
      case "AVATAR": {
        const { name, url } = columnData;
        return (
          <Avatar
            url={url}
            name={name}
            customContainerStyle={{ backgroundColor: "#e1e1e1" }}
          />
        );
      }
      case "ICON":
        return columnData;
      case "TEXT_COLUMN":
        return (
          <div className={classes.textCell} style={columnDataStyle}>
            {columnData}
          </div>
        );
      case "TEXT_COLUMN_WITH_SUBTEXT": {
        const customWithSubText = classNames("text-label-4", classes.subText);
        return (
          <>
            <div className={classes.textCell}>{columnData.label}</div>
            {columnData.subText && (
              <span className={customWithSubText}>{columnData.subText}</span>
            )}
          </>
        );
      }
      case "INT_COLUMN":
        return <div className={classes.intCell}>{columnData}</div>;
      case "ACTIONS": {
        return <></>;
      }
      case "CUSTOM":
        if (getCustomComponent) {
          return getCustomComponent({
            id,
            column,
            columnData,
          });
        }
        break;
      default:
        if (useCustomFuncForDefault && getCustomComponent) {
          return getCustomComponent({
            id,
            column,
            columnData,
          });
        } else {
          return null;
        }
    }
    return null;
  };

  let isItemClickable = false;
  if (listItem.isIndividualItemClickEnable !== undefined) {
    isItemClickable = listItem.isIndividualItemClickEnable;
  } else {
    isItemClickable = isItemClickEnable;
  }

  const rowClasses = classNames({
    [classes.row]: true,
    [listItem.rowClass]: true,
    [rowWrapperClass]: !!rowWrapperClass,
    [classes.mainRow]: listItem.isMainRow,
  });

  const cellStyle = {
    ...parentStyle,
    cursor: isItemClickable ? "pointer" : "auto",
  };

  const onClickTableCell = event => {
    if (!event.defaultPrevented && isItemClickable) {
      onItemClick(event);
    }
  };

  return (
    <div className={rowClasses} onClick={onClickTableCell} style={cellStyle}>
      {map(columns, column => {
        const { id, type, style, showOnHover } = column;
        const cellStyle = classNames(
          { [classes.cell]: true },
          { [classes.hideCell]: showOnHover },
          { [classes.actionCell]: type === "ACTIONS" ? true : false }
        );

        const columnData = getColumnData({
          column,
          id: listItem.id,
          columnData:
            typeof listItem[id] === "function"
              ? listItem[id](get(listItem[id], "extraParams", {}))
              : listItem[id],
        });

        return (
          <div className={cellStyle} key={id} style={style}>
            {columnData}
          </div>
        );
      })}
    </div>
  );
};

export default FeedListItemV2;

//Why does this code exist?
//This enables data to be displayed to the user in a visually appealing tabular list.
