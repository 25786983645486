// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SingleQuestion_singleMessageContainer_uevku{flex:1;display:flex;align-items:center;justify-content:center;gap:60px;width:100%;margin-bottom:20px}.SingleQuestion_questionNumber_S1RtS{display:flex;align-items:center;text-align:center;font-family:Abel;font-weight:500;font-size:1rem}.SingleQuestion_actionsColumn_F5tym{display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/routes/routes/AppLayout/routes/Projects/routes/Meeting/routes/PreparedQuestions/components/SingleQuestion/SingleQuestion.scss","webpack://./src/styles/Typography.scss"],"names":[],"mappings":"AAEA,6CACE,MAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,UAAA,CACA,kBAAA,CAGF,qCACE,YAAA,CACA,kBAAA,CACA,iBAAA,CCQA,gBAAA,CAZA,eAAA,CARA,cAAA,CDkBF,oCACE,YAAA,CACA,OAAA","sourcesContent":["@import \"styles/Typography.scss\";\r\n\r\n.singleMessageContainer {\r\n  flex: 1;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  gap: 60px;\r\n  width: 100%;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.questionNumber {\r\n  display: flex;\r\n  align-items: center;\r\n  text-align: center;\r\n  @include abel;\r\n  @include mediumFont;\r\n  @include fontSize(1.6rem);\r\n}\r\n\r\n.actionsColumn {\r\n  display: flex;\r\n  gap: 8px;\r\n}\r\n","$fontScalingFactor: 0.625;\r\n\r\n@mixin fontSize($baseSize) {\r\n  font-size: $baseSize * $fontScalingFactor;\r\n}\r\n\r\n@mixin regularFont {\r\n  font-weight: 400;\r\n}\r\n\r\n@mixin mediumFont {\r\n  font-weight: 500;\r\n}\r\n\r\n@mixin demiBoldFont {\r\n  font-weight: 600;\r\n}\r\n\r\n@mixin boldFont {\r\n  font-weight: 700;\r\n}\r\n\r\n@mixin abel {\r\n  font-family: Abel;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"singleMessageContainer": "SingleQuestion_singleMessageContainer_uevku",
	"questionNumber": "SingleQuestion_questionNumber_S1RtS",
	"actionsColumn": "SingleQuestion_actionsColumn_F5tym"
};
export default ___CSS_LOADER_EXPORT___;
