import React, { useLayoutEffect, useState } from "react";

import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import { useNavigate, useOutletContext } from "react-router-dom";

import {
  useDeleteAnsweredQuestions,
  useUpdateAnsweredQuestions,
} from "Meetings/modules/MeetingsMutations";
import { redirectMeetingRoutes } from "utils/RoutingHelpers";
import { useGetAllAnsweredQuestions } from "Meetings/modules/MeetingsQueries";

import Box from "UIComponents/Box";
import Loading from "UIComponents/Loading";
import QuestionAnswerBox from "./QuestionAnswerBox";
import EmptyStateComponent from "AppComponents/EmptyState";
import classes from "./QuestionsAsked.scss";

const initialEditQuestionState = {
  id: "",
  answer: "",
  question: "",
  shouldMakeApiCall: false,
};

const QuestionsAsked = () => {
  const navigate = useNavigate();
  const { meetingId, filledMeetingPurpose, isMeetingCompleted } = useOutletContext();
  const [editQuestionState, setEditQuestionState] = useState(initialEditQuestionState);

  const { data: answeredQuestions, isLoading } = useGetAllAnsweredQuestions({ meetingId });
  const { mutate: deleteAnsweredQuestions } = useDeleteAnsweredQuestions({ meetingId });
  const { mutate: updateAnsweredQuestions } = useUpdateAnsweredQuestions({ meetingId });

  useLayoutEffect(() => {
    redirectMeetingRoutes({ navigate, isMeetingCompleted, filledMeetingPurpose });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDeleteIcon = ({ questionId }) => {
    deleteAnsweredQuestions({ questionId });
  };

  const onFocusAnswer = ({ id, question, answer }) => {
    setEditQuestionState({ id, question, answer, shouldMakeApiCall: false });
  };

  const onChangeAnswer = event => {
    const { shouldMakeApiCall } = editQuestionState;
    let updatedState = {};
    const value = event.target.textContent;

    if (shouldMakeApiCall) updatedState = { answer: value };
    else updatedState = { answer: value, shouldMakeApiCall: true };

    setEditQuestionState(prevState => ({ ...prevState, ...updatedState }));
  };

  const onBlurAnswer = () => {
    const { id, answer, question, shouldMakeApiCall } = editQuestionState;
    if (shouldMakeApiCall) {
      updateAnsweredQuestions({ answer, question, questionId: id });
    }
  };

  return (
    <Box className={classes.questionsAskedContainer}>
      <div className={classes.header} />
      <div className={classes.questionsAnswersContainer}>
        {isLoading ? (
          <Loading />
        ) : isEmpty(answeredQuestions) ? (
          <EmptyStateComponent
            title={"Seems like no questions were asked in the meeting"}
          />
        ) : (
          map(answeredQuestions, ({ id, ...restProps }, index) => (
            <QuestionAnswerBox
              id={id}
              key={id}
              {...restProps}
              index={index}
              onBlurAnswer={onBlurAnswer}
              onFocusAnswer={onFocusAnswer}
              onChangeAnswer={onChangeAnswer}
              onClickDeleteIcon={onClickDeleteIcon}
            />
          ))
        )}
      </div>
    </Box>
  );
};

export default QuestionsAsked;

//Why does this code exist?
//Provides container for questions asked to be displayed to the end user.
