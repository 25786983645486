import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import UILabel from "UIComponents/UILabel";
import classes from "./DateSelector.scss";

const DateSelector = React.memo(props => {
  const {
    name,
    label,
    value,
    minTime,
    maxDate,
    maxTime,
    minDate,
    onChange,
    isDisabled,
    containerStyle = {},
    showTimeInput = false,
    timeFormat = "HH:mm",
    openDirection = "bottom",
    timeInputLabel = "Select Time",
    dateFormat = "MMMM d, yyyy h:mm aa",
  } = props;

  const handleOnChange = date => {
    onChange({ [name]: date });
  };

  return (
    <div className={classes.inputFieldContainer} style={containerStyle}>
      {!!label && <UILabel label={label} />}
      <DatePicker
        selected={value}
        disabled={isDisabled}
        onChange={handleOnChange}
        showTimeInput={showTimeInput}
        timeInputLabel={timeInputLabel}
        minTime={minTime && minTime}
        maxTime={maxTime && maxTime}
        maxDate={maxDate && maxDate}
        minDate={minDate && minDate}
        timeFormat={timeFormat}
        dateFormat={dateFormat}
        className={classes.inputText}
        popperPlacement={openDirection}
      />
    </div>
  );
});

DateSelector.displayName = "DateSelector";
export default DateSelector;
