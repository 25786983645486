// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DateSelector_inputFieldContainer_oU1xj{display:flex;flex-direction:column;gap:4px}.DateSelector_inputText_qrNZp{display:flex;width:100%;height:32px;border:1px solid #dddbda;outline:none;padding:8px;border-radius:4px}.DateSelector_inputText_qrNZp:disabled{cursor:not-allowed;background-color:gray}.DateSelector_inputText_qrNZp:disabled:hover{border-color:none !important}", "",{"version":3,"sources":["webpack://./src/UIComponents/DateSelector/DateSelector.scss"],"names":[],"mappings":"AAEA,wCACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,8BACE,YAAA,CACA,UAAA,CACA,WAAA,CACA,wBAAA,CACA,YAAA,CACA,WAAA,CACA,iBAAA,CAKA,uCACE,kBAAA,CACA,qBAAA,CACA,6CACE,4BAAA","sourcesContent":["@import \"styles/Colors.scss\";\r\n\r\n.inputFieldContainer {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 4px;\r\n}\r\n\r\n.inputText {\r\n  display: flex;\r\n  width: 100%;\r\n  height: 32px;\r\n  border: 1px solid #dddbda;\r\n  outline: none;\r\n  padding: 8px;\r\n  border-radius: 4px;\r\n  &:hover {\r\n  }\r\n  &:focus-within {\r\n  }\r\n  &:disabled {\r\n    cursor: not-allowed;\r\n    background-color: grey;\r\n    &:hover {\r\n      border-color: none !important;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputFieldContainer": "DateSelector_inputFieldContainer_oU1xj",
	"inputText": "DateSelector_inputText_qrNZp"
};
export default ___CSS_LOADER_EXPORT___;
