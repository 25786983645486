import React from "react";
import withAsyncRouteLoading from "Hocs/withAsyncRouteLoading";

const Home = withAsyncRouteLoading(() =>
  import(/* webpackChunkName: "Home" */ "./components")
);

const HomeRoutes = () => {
  return {
    path: "home",
    element: <Home documentTitle={"Home"} />,
    children: [],
  };
};

export default HomeRoutes;

//Why does this code exist?
//Exports the route for the homepage.
