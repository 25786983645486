import React from "react";

const CloseIcon = React.memo(props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill="#706E6B"
        d="m9.676 7.954 4-4.031a.446.446 0 0 0 0-.646l-.615-.646a.446.446 0 0 0-.646 0L8.384 6.66a.297.297 0 0 1-.43 0L3.921 2.6a.446.446 0 0 0-.646 0l-.646.646a.446.446 0 0 0 0 .646l4.03 4.031a.297.297 0 0 1 0 .43L2.6 12.416a.446.446 0 0 0 0 .646l.646.647a.446.446 0 0 0 .646 0l4.03-4.031a.297.297 0 0 1 .431 0l4.031 4.03a.446.446 0 0 0 .646 0l.646-.646a.446.446 0 0 0 0-.646l-4-4.03a.297.297 0 0 1 0-.431Z"
      />
    </svg>
  );
});

CloseIcon.displayName = "CloseIcon";
export default CloseIcon;
