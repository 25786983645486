import React, { useEffect, useState } from "react";

import find from "lodash/find";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import { areEqual, isSubstring } from "utils/HelperFunctions";

import UILabel from "UIComponents/UILabel";
import Dropdown from "UIComponents/Dropdown";
import CloseIcon from "SvgComponents/CloseIcon";
import SearchIcon from "SvgComponents/SearchIcon";
import InputTextField from "UIComponents/InputTextField";
import classes from "./LookUpSearchbar.scss";

const inputTextFieldWrapperStyle = { flex: "1" };
const overContainerStyle = {
  left: "0",
  top: "36px",
  borderRadius: "4px",
  border: "1px solid #DDDBDA",
};

const LookUpSearchbar = props => {
  const {
    name,
    label,
    options,
    inputProps = {},
    placeholder,
    inputType = "text",
    dropdownProps = {},
    disabled = false,
    selectedOptionKey,
    onChange = () => {},
    customOverContainerStyle = {},
  } = props;
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    let updatedOptions = [];
    updatedOptions = filter(options, ({ label }) =>
      isSubstring({ substring: searchTerm, masterString: label })
    );
    if (isEmpty(updatedOptions))
      updatedOptions = [{ key: "NO_RESULTS", type: "info", label: "No Results Found" }];

    setFilteredOptions(updatedOptions);
  }, [options, searchTerm]);

  const onClickClose = () => {
    setSearchTerm("");
    onChange({ [name]: "" });
  };

  const handleChange =
    ({ source }) =>
    (value, label) => {
      if (source === "dropdown") {
        onChange(value);
        setSearchTerm(label);
      } else if (source === "textField") {
        const selectedOption = find(options, ({ label }) =>
          areEqual({ str1: label, str2: value[name] })
        );
        if (selectedOption) {
          onChange({ [name]: selectedOption.key });
          setSearchTerm(selectedOption.label);
        } else {
          setSearchTerm(value[name]);
        }
      }
    };

  return (
    <div className={classes.container}>
      {!!label && <UILabel label={label} />}
      <Dropdown
        name={name}
        isDisabled={disabled}
        overlay={"checklist"}
        options={filteredOptions}
        customTriggerState={open}
        selectedOptionKey={selectedOptionKey}
        overContainerStyle={{ ...overContainerStyle, ...customOverContainerStyle }}
        onClickOptions={handleChange({ source: "dropdown" })}
        {...dropdownProps}
      >
        <InputTextField
          name={name}
          type={inputType}
          showIcon={true}
          value={searchTerm}
          disabled={disabled}
          trailingIcon={searchTerm ? <CloseIcon /> : <SearchIcon />}
          onClickTrailingIcon={onClickClose}
          placeholder={placeholder}
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          containerStyle={inputTextFieldWrapperStyle}
          updateInputField={handleChange({ source: "textField" })}
          {...inputProps}
        />
      </Dropdown>
    </div>
  );
};

export default LookUpSearchbar;
