import React from "react";

import { useNavigate } from "react-router-dom";

import Button from "UIComponents/Button";
import classes from "./PageNotFound.scss";

const PageNotFound = React.memo(() => {
  const navigate = useNavigate();

  const onClickGoBack = () => {
    navigate(-1);
  };

  return (
    <div className={classes.container}>
      <span className={classes.title}>{"404 Page"}</span>
      <span className={classes.subtitle}>{"The requested page doesn't exist"}</span>
      <Button onClick={onClickGoBack} size={"lg"}>
        {"Go Back"}
      </Button>
    </div>
  );
});

PageNotFound.displayName = "PageNotFound";
export default PageNotFound;

//Why does this code exist?
// This page is shown to users when the requested URL doesn't exist
