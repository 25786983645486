// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreparedQuestions_preparedQuestionsContainer_YVfSz{justify-content:flex-start;height:75.2vh}.PreparedQuestions_header_pm5nr{flex:1;display:flex;padding:30px 40px;align-items:center;justify-content:flex-end;max-height:92px;width:100%;border-bottom:2px solid #c9c9c9}", "",{"version":3,"sources":["webpack://./src/routes/routes/AppLayout/routes/Projects/routes/Meeting/routes/PreparedQuestions/components/PreparedQuestions.scss"],"names":[],"mappings":"AAEA,oDACE,0BAAA,CACA,aAAA,CAGF,gCACE,MAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CACA,wBAAA,CACA,eAAA,CACA,UAAA,CACA,+BAAA","sourcesContent":["@import \"styles/Colors.scss\";\r\n\r\n.preparedQuestionsContainer {\r\n  justify-content: flex-start;\r\n  height: 75.2vh;\r\n}\r\n\r\n.header {\r\n  flex: 1;\r\n  display: flex;\r\n  padding: 30px 40px;\r\n  align-items: center;\r\n  justify-content: flex-end;\r\n  max-height: 92px;\r\n  width: 100%;\r\n  border-bottom: 2px solid $borderSecondary;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preparedQuestionsContainer": "PreparedQuestions_preparedQuestionsContainer_YVfSz",
	"header": "PreparedQuestions_header_pm5nr"
};
export default ___CSS_LOADER_EXPORT___;
