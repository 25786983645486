import React from "react";

import map from "lodash/map";

import SingleCaptionBox from "Transcripts/components/SingleCaptionBox";
import classes from "./TranscriptPlayer.scss";

const TranscriptPlayer = props => {
  const { transcripts } = props;

  return (
    <div className={classes.mainContainer}>
      <div className={classes.outerScreenContainer}>
        <div className={classes.innerContainer}>
          {map(transcripts, ({ id, clockTime, speaker, transcript }) => {
            return (
              <SingleCaptionBox
                key={id}
                speaker={speaker}
                clockTime={clockTime}
                transcript={transcript}
              />
            );
          })}
        </div>
      </div>

      <div className={classes.controllerContainer} />
    </div>
  );
};

export default TranscriptPlayer;
