import React from "react";

import classNames from "classnames";

import AvatarIcon from "SvgComponents/AvatarIcon";
import { getNameInitials } from "utils/HelperFunctions";
import classes from "./Avatar.scss";

const avatarImageStyles = {
  borderRadius: "50%",
};

const getAvatarContainerStyle = ({ width, height, customContainerStyle }) => {
  return {
    width,
    height: height || width,
    ...customContainerStyle,
  };
};

const Avatar = React.memo(props => {
  const {
    url,
    name,
    height,
    className,
    width = 48,
    isAvatarClickable,
    customContainerStyle,
  } = props;

  const avatarContainerStyle = getAvatarContainerStyle({
    width,
    height,
    customContainerStyle,
  });
  const avatarContainerClass = classNames(
    { [className]: !!className },
    { [classes.avatarContainer]: true },
    { [classes.userProfileImage]: !!url },
    { [classes.clickable]: isAvatarClickable }
  );

  const nameInitials = name && getNameInitials({ name });

  return (
    <div className={avatarContainerClass} style={avatarContainerStyle}>
      {url ? (
        <img
          src={url}
          width={width}
          height={height || width}
          alt={"USER_AVATAR"}
          style={avatarImageStyles}
          referrerPolicy="no-referrer"
        />
      ) : name ? (
        <span>{nameInitials}</span>
      ) : (
        <AvatarIcon />
      )}
    </div>
  );
});

Avatar.displayName = "Avatar";
export default Avatar;

//Why does this code exist?
//This code enables the display of an avatar for the user profile icon. This code looks to use the profile image for the user from Google, but if not available it uses a default avatar.
