import React, { useEffect, useState } from "react";

import map from "lodash/map";
import get from "lodash/get";
import filter from "lodash/filter";
import { toast } from "react-hot-toast";
import { Container } from "react-smooth-dnd";

import SingleQuestion from "../SingleQuestion";
import classes from "./QuestionsList.scss";

const initialEditQuestionState = {
  id: null,
  question: "",
  shouldMakeApiCall: false,
};

const QuestionsList = props => {
  const {
    meetingId,
    questions,
    setQuestions,
    handleScroll,
    onClickDeleteIcon,
    addPreparedQuestion,
    isCreatingNewQuestion,
    questionsContainerRef,
    updatePreparedQuestion,
    updatePreparedQuestionsSequence,
  } = props;

  const [editQuestionState, setEditQuestionState] = useState(initialEditQuestionState);
  const { id, question, shouldMakeApiCall } = editQuestionState;

  useEffect(() => {
    const beforeUnloadHandler = event => {
      if (shouldMakeApiCall) {
        event.preventDefault();
        event.returnValue = "You have unsaved changes. Are you sure you want to leave?";
        return "";
      }
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => window.removeEventListener("beforeunload", beforeUnloadHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldMakeApiCall]);

  const onDrop = event => {
    const { removedIndex, addedIndex, payload } = event;
    if (removedIndex === null && addedIndex === null) return questions;
    const result = [...questions];
    let itemToAdd = payload;
    if (removedIndex !== null) itemToAdd = result.splice(removedIndex, 1)[0];
    if (addedIndex !== null) result.splice(addedIndex, 0, itemToAdd);
    setQuestions(result);
    updatePreparedQuestionsSequence({ questions: result });
  };

  const onFocusQuestion = ({ id, question }) => {
    setEditQuestionState({ id, question, shouldMakeApiCall: false });
  };

  const onChangeQuestion = event => {
    const value = event.target.textContent;
    setEditQuestionState(prevState => ({
      ...prevState,
      question: value,
      shouldMakeApiCall: true,
    }));
  };

  const onBlurQuestion = () => {
    if (id === "NEW_QUESTION") return;
    if (!question) {
      toast.error("Question Cannot be Empty");
    } else if (shouldMakeApiCall) {
      updatePreparedQuestion({ questionId: id, meetingId, question });
      setEditQuestionState(initialEditQuestionState);
    }
  };

  const onClickCheckIcon = async () => {
    try {
      if (!question) {
        toast.error("Question Cannot be Empty");
      } else {
        const response = await addPreparedQuestion({ question, meetingId });
        const newQuestionId = get(response, "id", null);
        if (!newQuestionId) throw new Error("ERROR");
        const questionsWithUpdatedSequence = map(questions, updatedQuestion => {
          const { id } = updatedQuestion;
          if (id === "NEW_QUESTION") return { id: newQuestionId };
          else return updatedQuestion;
        });
        updatePreparedQuestionsSequence({ questions: questionsWithUpdatedSequence });
        setEditQuestionState(initialEditQuestionState);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const onClickCrossIcon = ({ questionId }) => {
    if (questionId === "NEW_QUESTION") {
      setQuestions(filter(questions, ({ id }) => id !== "NEW_QUESTION"));
    }
  };

  return (
    <div
      onScroll={handleScroll}
      ref={questionsContainerRef}
      className={classes.messagesContainer}
    >
      <Container
        onDrop={onDrop}
        animationDuration={400}
        style={{ width: "100%" }}
        dragHandleSelector={"#dragHandleSelector"}
      >
        {map(questions, ({ id, question }, index) => {
          const isNewQuestion = id === "NEW_QUESTION";
          return (
            <SingleQuestion
              id={id}
              key={id}
              index={index}
              question={question}
              isNewQuestion={isNewQuestion}
              onBlurQuestion={onBlurQuestion}
              onFocusQuestion={onFocusQuestion}
              onClickCheckIcon={onClickCheckIcon}
              onChangeQuestion={onChangeQuestion}
              onClickCrossIcon={onClickCrossIcon}
              onClickDeleteIcon={onClickDeleteIcon}
              isCreatingNewQuestion={isCreatingNewQuestion}
            />
          );
        })}
      </Container>
    </div>
  );
};

export default QuestionsList;
