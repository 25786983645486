import map from "lodash/map";

// App Config Constants
// export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;

//Subscription Plan Types
export const PLAN_TYPE_PRO = "PRO";
export const PLAN_TYPE_BASIC = "BASIC";

// Org Access Level Types
export const ORG_ACCESS_LEVEL_TYPE_VIEW = "VIEW";
export const ORG_ACCESS_LEVEL_TYPE_OWNER = "OWNER";
export const ORG_ACCESS_LEVEL_TYPE_ADMIN = "ADMIN";
export const ORG_ACCESS_LEVEL_TYPE_MANAGE = "MANAGE";
export const ORG_ACCESS_LEVEL_TYPE_PRIVATE = "PRIVATE";

export const orgMemberEnumToDisplayMapping = {
  [ORG_ACCESS_LEVEL_TYPE_PRIVATE]: "Private",
  [ORG_ACCESS_LEVEL_TYPE_VIEW]: "View All",
  [ORG_ACCESS_LEVEL_TYPE_MANAGE]: "View/Edit All",
  [ORG_ACCESS_LEVEL_TYPE_ADMIN]: "Administrator",
  [ORG_ACCESS_LEVEL_TYPE_OWNER]: "Owner",
};

export const orgMemberAccessLevelOptions = map(
  orgMemberEnumToDisplayMapping,
  (value, key) => ({ key, label: value })
);

// Project Access Level Types
export const PROJECT_ACCESS_LEVEL_TYPE_VIEW = "VIEW";
export const PROJECT_ACCESS_LEVEL_TYPE_MANAGE = "MANAGE";
export const PROJECT_ACCESS_LEVEL_TYPE_OWNER = "OWNER";

export const projectMemberEnumToDisplayMapping = {
  [PROJECT_ACCESS_LEVEL_TYPE_VIEW]: "View",
  [PROJECT_ACCESS_LEVEL_TYPE_MANAGE]: "Manage",
  [PROJECT_ACCESS_LEVEL_TYPE_OWNER]: "Owner",
};

export const projectMemberAccessLevelOptions = map(
  projectMemberEnumToDisplayMapping,
  (value, key) => ({ key, label: value })
);

const projectAccessLevelOptions = {
  [PROJECT_ACCESS_LEVEL_TYPE_VIEW]: [],
  [PROJECT_ACCESS_LEVEL_TYPE_MANAGE]: [
    { key: PROJECT_ACCESS_LEVEL_TYPE_VIEW, label: "VIEW" },
  ],
  [PROJECT_ACCESS_LEVEL_TYPE_OWNER]: [
    { key: PROJECT_ACCESS_LEVEL_TYPE_VIEW, label: "VIEW" },
    { key: PROJECT_ACCESS_LEVEL_TYPE_MANAGE, label: "Manage" },
  ],
  ALL: [
    { key: PROJECT_ACCESS_LEVEL_TYPE_VIEW, label: "VIEW" },
    { key: PROJECT_ACCESS_LEVEL_TYPE_MANAGE, label: "Manage" },
    { key: PROJECT_ACCESS_LEVEL_TYPE_OWNER, label: "Owner" },
  ],
};

export const getProjectAccessLevelOptions = ({ projectAccessType }) => {
  return projectAccessLevelOptions[projectAccessType];
};
