import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const orgPersistConfig = {
  key: "org",
  storage: storage,
};

const initialState = {
  id: "",
  name: "",
  plan: "",
  accessType: "",
  isOrgActive: false,
};

const reducers = {
  setOrg: (state, action) => {
    const { org } = action.payload;
    return { ...state, ...org };
  },
  setOrgInitialState: state => {
    return { ...state, ...initialState };
  },
};

const { actions, reducer: orgReducer } = createSlice({
  name: "org",
  initialState,
  reducers,
});

export const { setOrg, setOrgInitialState } = actions;
export default persistReducer(orgPersistConfig, orgReducer);
