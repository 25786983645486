import React from "react";

import { Draggable } from "react-smooth-dnd";

import Button from "UIComponents/Button";
import CheckIcon from "SvgComponents/CheckIcon";
import CloseIcon from "SvgComponents/CloseIcon";
import MessageBox from "UIComponents/MessageBox";
import DeleteIcon from "SvgComponents/DeleteIcon";
import classes from "./SingleQuestion.scss";

const SingleQuestion = React.memo(props => {
  const {
    id,
    index,
    question,
    isNewQuestion,
    onBlurQuestion,
    onFocusQuestion,
    onClickCrossIcon,
    onChangeQuestion,
    onClickCheckIcon,
    onClickDeleteIcon,
    isCreatingNewQuestion,
  } = props;

  return (
    <Draggable key={id} style={{ display: "flex", width: "100%" }}>
      <div className={classes.singleMessageContainer}>
        <div className={classes.questionNumber}>{index + 1}</div>
        <MessageBox
          key={id}
          text={question}
          isEditable={!isCreatingNewQuestion || isNewQuestion}
          isDraggable={true}
          onBlur={onBlurQuestion}
          onInput={onChangeQuestion}
          dragHandleProps={{ id: "dragHandleSelector" }}
          onFocus={() => onFocusQuestion({ id, question })}
        />

        <div className={classes.actionsColumn}>
          {isNewQuestion ? (
            <Button
              variant={"plain"}
              isIconButton={true}
              isDisabled={isCreatingNewQuestion && !isNewQuestion}
              onClick={() => onClickCrossIcon({ questionId: id })}
            >
              <CloseIcon />
            </Button>
          ) : (
            <Button
              variant={"plain"}
              isIconButton={true}
              isDisabled={isCreatingNewQuestion && !isNewQuestion}
              onClick={() => onClickDeleteIcon({ questionId: id })}
            >
              <DeleteIcon />
            </Button>
          )}

          {isNewQuestion && (
            <Button variant={"plain"} isIconButton={true} onClick={onClickCheckIcon}>
              <CheckIcon />
            </Button>
          )}
        </div>
      </div>
    </Draggable>
  );
});

SingleQuestion.displayName = "SingleQuestion";
export default SingleQuestion;
