import React from "react";

const HomeIcon = React.memo(props => {
  const { fill } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
      <path
        fill={fill}
        d="M14.724 7.892h-1.462v6.431c0 .176-.116.293-.292.293h-2.923c-.175 0-.292-.117-.292-.293v-4.97H6.247v4.97c0 .176-.117.293-.292.293H3.032c-.176 0-.293-.117-.293-.293v-6.43H1.278c-.117 0-.234-.06-.263-.176-.059-.117-.03-.234.058-.322L7.796.672c.117-.117.322-.117.41 0l6.723 6.723c.087.088.087.205.058.322-.03.117-.146.175-.263.175Z"
      />
    </svg>
  );
});

HomeIcon.displayName = "HomeIcon";
export default HomeIcon;
