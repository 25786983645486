import React from "react";

const Spinner = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      preserveAspectRatio="xMidYMid"
      style={{
        margin: "auto",
        background: "0 0",
        display: "block",
        shapeRendering: "auto",
      }}
      viewBox="0 0 100 100"
      {...props}
    >
      <circle
        cx={50}
        cy={50}
        r={30}
        fill="none"
        stroke="#23abc8"
        strokeLinecap="round"
        strokeWidth={8}
      >
        <animateTransform
          attributeName="transform"
          dur="2s"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="rotate"
          values="0 50 50;180 50 50;720 50 50"
        />
        <animate
          attributeName="stroke-dasharray"
          dur="2s"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882"
        />
      </circle>
    </svg>
  );
});

Spinner.displayName = "Spinner";
export default Spinner;
