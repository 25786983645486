export const backgroundColors = {
  default: "#FFFFFF",
  white: "#FFFFFF",
  primary: "#23ABC8",
  lightSubtle: "#F5F5F5",
  subtle: "#EBEBEB",
  darkSubtle: "#666666",
  hover: "#9D9D9D",
  darkHover: "#D9D9D9",
  disabled: "#E0E0E0",
  active: "#FAFAFA",
  danger: "#E14942",
  success: "#3EAF3F",
  textField: "#CFCFCF",
  bgSubtleButton: "#e8e8e8",
};

export const borderColors = {
  lightSubtle: "#EAEAEA",
  darkSubtle: "#666666",
  primary: "#23ABC8",
};

export const fontColors = {
  default: "#000000",
  primary: "#23ABC8",
  white: "#FFFFFF",
  disabled: "#9A9A9A",
};

//Why does this code exist?
//This ensures the end user has a consistent UX with consistent color schemes.
