import axios from "axios";
import get from "lodash/get";

import apiEndpoints from "Constants/ApiEndpoints";

const axiosInstance = axios.create({
  baseURL: apiEndpoints.BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 1000 * 60,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    return get(response, "data", []);
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;

//Why does this code exist?
//This enables the user to read and write data. It also handles the task of formatting data and sending authorization tokens.
