import React from "react";

import classNames from "classnames";

import UILabel from "UIComponents/UILabel";
import classes from "./InputTextField.scss";
import SearchIcon from "SvgComponents/SearchIcon";

const getUpdatedInputStyle = ({ disabled, inputStyle }) => {
  let updatedInputStyle = inputStyle;
  if (disabled) {
    updatedInputStyle = {
      ...updatedInputStyle,
      cursor: "not-allowed",
    };
  }
  return updatedInputStyle;
};

const InputTextField = React.memo(props => {
  const {
    type = "text",
    name,
    value,
    label,
    disabled,
    autoFocus,
    className,
    inputStyle,
    placeholder,
    autoComplete,
    containerStyle,
    updateInputField,
    showIcon = false,
    trailingIcon = <SearchIcon />,
    onClickTrailingIcon = () => {},
    ...inputProps
  } = props;

  const updateValue = event => {
    const value = event.target.value;
    if (updateInputField) {
      if (name) {
        updateInputField({ [name]: value });
      } else {
        updateInputField(value);
      }
    }
  };

  const updatedInputStyle = getUpdatedInputStyle({
    disabled,
    inputStyle,
  });

  const inputText = classNames({ [className]: true }, { [classes.inputText]: true });

  return (
    <div className={classes.inputFieldContainer} style={containerStyle}>
      {!!label && <UILabel label={label} />}
      <div className={classes.inputWrapper}>
        <input
          min={0}
          type={type}
          name={name}
          disabled={disabled}
          autoFocus={autoFocus}
          className={inputText}
          onChange={updateValue}
          style={updatedInputStyle}
          placeholder={placeholder}
          value={value ? value : ""}
          autoComplete={autoComplete ? true : "off"}
          {...inputProps}
        />
        {showIcon && (
          <span className={classes.trailingIcon} onClick={onClickTrailingIcon}>
            {trailingIcon}
          </span>
        )}
      </div>
    </div>
  );
});

InputTextField.displayName = "InputTextField";
export default InputTextField;

//Why does this code exist?
//This code enables the user to input text values throughout the application for a single line of text.
