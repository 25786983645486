import React from "react";

import classNames from "classnames";
import classes from "./UILabel.scss";

const UILabel = React.memo(props => {
  const {
    label = "",
    labelStyle = {},
    labelClass,
    labelComponent,
    labelContainerStyle,
  } = props;

  const labelClasses = classNames(
    { [classes.inputLabel]: true },
    { [classes[labelClass]]: !!labelClass }
  );

  return (
    <div className={classes.container} style={labelContainerStyle}>
      {labelComponent && labelComponent}
      {!!label && (
        <div className={labelClasses} style={labelStyle}>
          {label}
        </div>
      )}
    </div>
  );
});

UILabel.displayName = "UILabel";
export default UILabel;

//Why does this code exist?
//This enables labels to be displayed to users throughout the application while supporting reusabiliity.
