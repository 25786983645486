// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FullScreenLoader_overlayContainer_fqJqw{position:fixed;top:0;left:0;bottom:0;right:0;display:flex;width:100%;height:100%;z-index:1100;justify-content:center;align-items:center;flex-direction:column;overflow:hidden;cursor:default;background-color:rgba(0,0,0,.7)}.FullScreenLoader_fullScreenModalContent_uuJIN{width:100%;height:100%;border-radius:0;background-color:rgba(0,0,0,0);justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/UIComponents/FullScreenLoader/FullScreenLoader.scss"],"names":[],"mappings":"AAAA,yCACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,QAAA,CACA,OAAA,CACA,YAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,eAAA,CACA,cAAA,CACA,+BAAA,CAGF,+CACE,UAAA,CACA,WAAA,CACA,eAAA,CACA,8BAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".overlayContainer {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  bottom: 0;\r\n  right: 0;\r\n  display: flex;\r\n  width: 100%;\r\n  height: 100%;\r\n  z-index: 1100;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  overflow: hidden;\r\n  cursor: default;\r\n  background-color: rgba(0, 0, 0, 0.7);\r\n}\r\n\r\n.fullScreenModalContent {\r\n  width: 100%;\r\n  height: 100%;\r\n  border-radius: 0;\r\n  background-color: transparent;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlayContainer": "FullScreenLoader_overlayContainer_fqJqw",
	"fullScreenModalContent": "FullScreenLoader_fullScreenModalContent_uuJIN"
};
export default ___CSS_LOADER_EXPORT___;
