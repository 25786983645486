import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
} from "redux-persist";
// import logger from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";
import { batchedSubscribe } from "redux-batched-subscribe";

import debounce from "lodash/debounce";
import createRootReducer from "./reducers";

const debounceNotify = debounce(notify => notify());
const ignoredActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];
const defaultMiddlewareConfig = { serializableCheck: { ignoredActions } };

const createStore = () => {
  const store = configureStore({
    reducer: createRootReducer(),
    devTools: process.env.NODE_ENV !== "production",
    enhancers: [batchedSubscribe(debounceNotify)],
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware(defaultMiddlewareConfig),
      // logger,
    ],
  });

  store.asyncReducers = {};
  store.injectReducer = ({ key, asyncReducer }) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createRootReducer(store.asyncReducers));
  };

  if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./reducers", () => {
      const newRootReducer = require("./reducers").default;
      store.replaceReducer(newRootReducer);
    });
  }

  return store;
};

const store = createStore();
export default store;
export const persistedStore = persistStore(store);
