/* eslint-disable react/display-name */
import React, { lazy, Suspense } from "react";
import Loading from "UIComponents/Loading";

const WithAsyncRouteLoading = component => {
  const LazyComponent = lazy(component);

  return props => {
    const { documentTitle, ...rest } = props;
    if (documentTitle) document.title = `SwiftBa | ${documentTitle}`;
    else document.title = "SwiftBa";
    return (
      <Suspense fallback={<Loading />}>
        <LazyComponent {...rest} />
      </Suspense>
    );
  };
};

export default WithAsyncRouteLoading;
