import { useQuery } from "@tanstack/react-query";

import apiClient from "services/api/apiClient";
import apiEndpoints, { dataCacheKeys } from "Constants/ApiEndpoints";

// Single Meeting
const getSingleMeeting = async ({ meetingId }) => {
  const payload = { params: { id: meetingId } };
  const response = await apiClient.get({
    payload,
    url: apiEndpoints.GET_SINGLE_MEETING,
  });
  return response.meeting;
};

export const useGetSingleMeeting = ({ meetingId, params = {} }) => {
  return useQuery({
    queryKey: dataCacheKeys.meeting({ meetingId }),
    queryFn: () => getSingleMeeting({ meetingId }),
    ...params,
  });
};

// Prepared Questions
const getAllPreparedQuestions = async ({ meetingId }) => {
  const payload = { params: { meetingId } };
  const response = await apiClient.get({
    payload,
    url: apiEndpoints.GET_ALL_PREPARED_QUESTIONS,
  });
  return response.preparedQuestions;
};

export const useGetAllPreparedQuestions = ({ meetingId }) => {
  return useQuery({
    queryKey: dataCacheKeys.preparedQuestions({ meetingId }),
    queryFn: () => getAllPreparedQuestions({ meetingId }),
  });
};

// AI Answered Questions
const getAllAnsweredQuestions = async ({ meetingId }) => {
  const payload = { params: { id: meetingId } };
  const response = await apiClient.get({
    payload,
    url: apiEndpoints.GET_ALL_ANSWERED_QUESTIONS,
  });
  return response.answeredQuestions;
};

export const useGetAllAnsweredQuestions = ({ meetingId }) => {
  return useQuery({
    queryKey: dataCacheKeys.questionsAsked({ meetingId }),
    queryFn: () => getAllAnsweredQuestions({ meetingId }),
  });
};

// Transcripts
const getMeetingTranscript = async ({ meetingId }) => {
  const payload = { params: { meetingId } };
  const response = await apiClient.get({
    payload,
    url: apiEndpoints.GET_MEETING_TRANSCRIPT,
  });
  return response;
};

export const useGetMeetingTranscript = ({ meetingId }) => {
  return useQuery({
    queryKey: dataCacheKeys.transcript({ meetingId }),
    queryFn: () => getMeetingTranscript({ meetingId }),
  });
};
