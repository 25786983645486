import React from "react";
import { Navigate } from "react-router-dom";
import withAsyncRouteLoading from "Hocs/withAsyncRouteLoading";

import TranscriptRoutes from "./routes/Transcripts";
import QuestionsAskedRoutes from "./routes/QuestionsAsked";
import PreparedQuestionsRoutes from "./routes/PreparedQuestions";
import PreMeetingContextRoutes from "./routes/PreMeetingContext";

const ProjectLayout = withAsyncRouteLoading(() =>
  import(/* webpackChunkName: "projectLayout" */ "Layouts/ProjectLayout/ProjectLayout")
);

const MeetingsRoutes = () => {
  return {
    path: ":projectId/meetings/:meetingId",
    element: <ProjectLayout source={"meetings"} />,
    children: [
      { index: true, element: <Navigate to="transcript" replace /> },
      TranscriptRoutes(),
      QuestionsAskedRoutes(),
      PreparedQuestionsRoutes(),
      PreMeetingContextRoutes(),
    ],
  };
};

export default MeetingsRoutes;

//Why does this code exist?
//This enables the routes for projects, so that the end user can access project records.
