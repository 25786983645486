import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import findIndex from "lodash/findIndex";
import { useNavigate, useOutletContext } from "react-router-dom";

import {
  useAddPrepareQuestion,
  useDeletePrepareQuestion,
  useUpdatePreparedQuestion,
  useUpdatePreparedQuestionsSequence,
} from "Meetings/modules/MeetingsMutations";
import { redirectMeetingRoutes } from "utils/RoutingHelpers";
import { useGetAllPreparedQuestions } from "Meetings/modules/MeetingsQueries";

import Box from "UIComponents/Box";
import Button from "UIComponents/Button";
import Loading from "UIComponents/Loading";
import QuestionsList from "./QuestionsList";
import EmptyStateComponent from "AppComponents/EmptyState";
import classes from "./PreparedQuestions.scss";

const PreparedQuestions = () => {
  const navigate = useNavigate();
  const { meetingId, filledMeetingPurpose } = useOutletContext();
  const { data: preparedQuestions, isLoading } = useGetAllPreparedQuestions({ meetingId });

  const questionsContainerRef = useRef(null);
  const [questions, setQuestions] = useState(preparedQuestions);
  const [visibleQuestionIndex, setVisibleQuestionIndex] = useState(0);

  const { mutateAsync: addPreparedQuestion } = useAddPrepareQuestion({ meetingId });
  const { mutate: deletePreparedQuestion } = useDeletePrepareQuestion({ meetingId });
  const { mutate: updatePreparedQuestion } = useUpdatePreparedQuestion({ meetingId });
  const { mutate: updatePreparedQuestionsSequence } = useUpdatePreparedQuestionsSequence({
    meetingId,
  });

  useEffect(() => {
    setQuestions(preparedQuestions);
  }, [preparedQuestions]);

  useLayoutEffect(() => {
    redirectMeetingRoutes({ navigate, filledMeetingPurpose, isMeetingCompleted: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCreatingNewQuestion = !!find(questions, { id: "NEW_QUESTION" });

  const handleScroll = () => {
    if (!questionsContainerRef.current || isCreatingNewQuestion) return;

    const containerRect = questionsContainerRef.current.getBoundingClientRect();
    const questionsDivs =
      questionsContainerRef.current.querySelectorAll("#dragHandleSelector");
    const visibleIndex = findIndex(questionsDivs, div => {
      const divRect = div.getBoundingClientRect();
      return divRect.bottom > containerRect.top && divRect.top < containerRect.bottom;
    });

    if (visibleIndex !== -1) setVisibleQuestionIndex(visibleIndex + 1);
  };

  const onClickAddQuestion = () => {
    const newEmptyQuestion = { id: "NEW_QUESTION", question: "" };
    const updatedQuestions = [...questions];
    updatedQuestions.splice(visibleQuestionIndex, 0, newEmptyQuestion);
    setQuestions(updatedQuestions);
  };

  const onClickDeleteIcon = ({ questionId }) => {
    deletePreparedQuestion({ questionId });
  };

  return (
    <Box className={classes.preparedQuestionsContainer}>
      <div className={classes.header}>
        <Button onClick={onClickAddQuestion} isDisabled={isCreatingNewQuestion}>
          {"+ Add Question"}
        </Button>
      </div>

      {isLoading ? (
        <Loading />
      ) : isEmpty(questions) ? (
        <EmptyStateComponent title={"No prepared questions as of yet"} />
      ) : (
        <QuestionsList
          meetingId={meetingId}
          questions={questions}
          setQuestions={setQuestions}
          handleScroll={handleScroll}
          onClickDeleteIcon={onClickDeleteIcon}
          addPreparedQuestion={addPreparedQuestion}
          isCreatingNewQuestion={isCreatingNewQuestion}
          questionsContainerRef={questionsContainerRef}
          updatePreparedQuestion={updatePreparedQuestion}
          updatePreparedQuestionsSequence={updatePreparedQuestionsSequence}
        />
      )}
    </Box>
  );
};

export default PreparedQuestions;

//Why does this code exist?
//The end-user needs to prepare questions I have a meeting. They also need debility to re-order those questions. This is code provides that functionality.
