import React from "react";

const CaptionIcon = React.memo(props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill="#706E6B"
        d="M16.73.961H3.307A2.331 2.331 0 0 0 .961 3.308v13.346A2.331 2.331 0 0 0 3.307 19h13.385a2.331 2.331 0 0 0 2.346-2.346V3.308C19.076 2 17.999.96 16.73.96ZM4.076 4.885c0-.424.346-.77.77-.77h8.807c.423 0 .77.346.77.77v.807c0 .423-.347.77-.77.77H4.807a.771.771 0 0 1-.77-.77v-.808h.04Zm9.27 10.23c0 .423-.347.77-.77.77H4.884a.772.772 0 0 1-.77-.77v-.769c0-.423.347-.77.77-.77h7.692c.423 0 .77.347.77.77v.77Zm2.576-4.73c0 .423-.346.769-.769.769H4.884a.771.771 0 0 1-.77-.77v-.769c0-.423.347-.769.77-.769h10.23c.424 0 .77.346.77.77v.768h.038Z"
      />
    </svg>
  );
});

CaptionIcon.displayName = "CaptionIcon";
export default CaptionIcon;
