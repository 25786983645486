import React from "react";
import { Navigate } from "react-router-dom";

import UsersRoutes from "./routes/Users";
import BillingRoutes from "./routes/Billing";
import OrganizationsRoutes from "./routes/Organizations";

import withAsyncRouteLoading from "Hocs/withAsyncRouteLoading";

const Settings = withAsyncRouteLoading(() =>
  import(/* webpackChunkName: "settings" */ "./components")
);

const SettingsRoutes = () => {
  return {
    path: "settings",
    element: <Settings documentTitle={"Settings"} />,
    children: [
      {
        index: true,
        element: <Navigate to="users" replace />,
      },
      UsersRoutes(),
      BillingRoutes(),
      OrganizationsRoutes(),
    ],
  };
};

export default SettingsRoutes;

//Why does this code exist?
//This exports the modules to make them available for import.
