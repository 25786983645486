import React, { useLayoutEffect } from "react";

import { redirectMeetingRoutes } from "utils/RoutingHelpers";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useGetMeetingTranscript } from "Meetings/modules/MeetingsQueries";

import Box from "UIComponents/Box";
import Loading from "UIComponents/Loading";
import TranscriptPlayer from "AppComponents/TranscriptPlayer";
import classes from "./Transcript.scss";

const Transcript = () => {
  const navigate = useNavigate();
  const { meetingId, filledMeetingPurpose, isMeetingCompleted } = useOutletContext();
  const { data: transcripts, isLoading } = useGetMeetingTranscript({ meetingId });

  useLayoutEffect(() => {
    redirectMeetingRoutes({ navigate, isMeetingCompleted, filledMeetingPurpose });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.transcriptBoxContainer}>
      <div className={classes.header} />
      <div className={classes.bodyContainer}>
        {isLoading ? <Loading /> : <TranscriptPlayer transcripts={transcripts} />}
      </div>
    </Box>
  );
};

export default Transcript;
