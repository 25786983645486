import React from "react";

import classNames from "classnames";
import { borderColors } from "Constants/ColorConstants";

import Button from "UIComponents/Button";
import DragIcon from "SvgComponents/DragIcon";
import CaptionIcon from "SvgComponents/CaptionIcon";
import QuestionAnswerLink from "SvgComponents/QuestionAnswerLink";
import classes from "./MessageBox.scss";

const getUpdatedContainerStyle = ({ isAIGenerated, customContainerStyle }) => {
  let updatedStyle = {};
  if (isAIGenerated) {
    updatedStyle = { ...updatedStyle, borderColor: borderColors.primary };
  }
  return { ...updatedStyle, ...customContainerStyle };
};

const getLabel = ({ isAIGenerated }) => {
  if (isAIGenerated) return "AI";
  return <CaptionIcon />;
};

const MessageBox = React.memo(props => {
  const {
    text,
    className,
    dragHandleProps,
    showLabel = false,
    isEditable = false,
    showConnector = false,
    isDraggable = false,
    isAIGenerated = false,
    customContainerStyle,
    onBlur = () => {},
    onFocus = () => {},
    onInput = () => {},
  } = props;

  const containerClasses = classNames({
    [className]: !!className,
    [classes.messageBoxContainer]: true,
  });
  const label = getLabel({ isAIGenerated });
  const updatedContainerStyle = getUpdatedContainerStyle({
    isAIGenerated,
    customContainerStyle,
  });

  return (
    <div className={containerClasses} style={updatedContainerStyle}>
      {showConnector && (
        <div className={classes.questionAnswerLink}>
          <QuestionAnswerLink stroke={isAIGenerated ? "#23ABC8" : "#DDDBDA"} />
        </div>
      )}
      {isDraggable && (
        <span {...dragHandleProps}>
          <Button variant={"plain"} isIconButton={true} className={classes.dragIcon}>
            <DragIcon />
          </Button>
        </span>
      )}
      {showLabel && <div className={classes.messageLabel}>{label}</div>}
      <div
        onBlur={onBlur}
        onFocus={onFocus}
        onInput={onInput}
        contentEditable={isEditable}
        className={classes.messageContainer}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
});

MessageBox.displayName = "MessageBox";
export default MessageBox;

//Why does this code exist?
//This code enables a text box to be shown on the web application with support for dragging. An example of this use is the display and drag of prepared questions.
