import React from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactQueryProvider } from "services/api/ReactQueryProvider";

import AppContainer from "AppContainer";
import ToastBar from "UIComponents/ToastBar";
import store, { persistedStore } from "store/store";
import FullScreenLoader from "UIComponents/FullScreenLoader";

const App = () => {
  return (
    <React.StrictMode>
      <ReactQueryProvider>
        <BrowserRouter>
          <Provider store={store}>
            <PersistGate loading={<FullScreenLoader />} persistor={persistedStore}>
              <AppContainer />
              <ToastBar />
              <ReactQueryDevtools initialIsOpen />
            </PersistGate>
          </Provider>
        </BrowserRouter>
      </ReactQueryProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

//Why does this code exist?
//Without this file, the React application wouldn't run. This file sets up the main structure of the application, integrates it with Redux for state management and react-router-dom for routing, and mounts it to the DOM.
